import React from 'react'
import TodayqNews from './TodayqNews'
import PopluarPackage from './PopluarPackage'

const Index = () => {
  return (
    <div>
      <TodayqNews/>
    </div>
  )
}

export default Index
