import React, { useRef, useState } from "react";
import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MarketPlaceSidebar from "./MarketplaceSideBar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth.action";

const Header = () => {

  const location = useLocation()
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const toggleRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    toggleRef.current.classList.toggle("hidden");
  };

  const handleLogout = async () => {
    await dispatch(logout());
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg !sticky top-[0px] z-50 w-[100%] bg-[#fff] border-b py-md-0 py-2">
        <div className="container-fluid !px-[32px]">
          <button
            className="navbar-brand"
            onClick={() => {
              if (user?.userType === "publisher") navigate("/dashboard");
              else navigate("/my-billing");
            }}
          >
            <img src="/assets/images/Logo/Koinpr-Logo-Dark.png" alt="koinpr logo" />
          </button>
          <button
            className="navbar-toggler border-0"
            type="button"
            onClick={toggleSidebar}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse visible navbar-collapse"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav items-center gap-5 me-auto mb-2 mb-lg-0 !mx-auto">
              {/* <li className="nav-item">
                <Link
                  className="nav-link active !text-[#fff] !font-[590] !text-[16px] !leading-[19.2px]"
                  aria-current="page"
                  to="#"
                >
                  ✨<span className="gradient-text">360º Marketing</span>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link text-gradient !font-[600] !py-[20px]"
                  aria-current="page"
                  to="#one"
                >
                  Koinpr One
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-gradient !font-[600] !py-[20px]"
                  aria-current="page"
                  to="#eod"
                >
                  Koinpr EOD
                </Link>
              </li>
              <li className="nav-item relative">
                <Link
                  className={`${location.pathname === '/Marketplace' ? 'active-borders' : ''} nav-link !text-black !font-[600] !py-[20px]`}
                  aria-current="page"
                  to="/Marketplace"
                >
                  Marketplace
                </Link>
              </li>
              <li className="nav-item relative">
                <Link
                  className={`${location.pathname === '/Package' ? 'active-borders' : ''} nav-link !text-black !font-[600] !py-[20px]`}
                  aria-current="page"
                  to="/Package"
                >
                  Packages
                </Link>
              </li>
            </ul>
            <div className="flex items-center gap-3 justify-center">
              {isAuthenticated && (
                <>
                  <div className="flex items-center gap-3">
                    <div>
                      <div className="w-[40px] h-[40px] bg-[#3053A6] rounded-full flex items-center justify-center text-white text-lg">
                        {user?.fullname?.charAt(0).toUpperCase()}
                      </div>
                    </div>
                    <div>
                      <h4 className="font-[500] text-[16px] leading-[19.2px] mb-1">
                        {user?.fullname}
                      </h4>
                      <p className="font-[400] text-[13px] leading-[19.5px] mb-0 capitalize text-[#787878]">
                        {user?.userType}
                      </p>
                    </div>
                  </div>
                  <div className="relative select-none">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      {isOpen ? (
                        <IoIosArrowUp className="text-[18px]" />
                      ) : (
                        <IoIosArrowDown className="text-[18px]" />
                      )}
                    </div>
                    <div
                      ref={toggleRef}
                      className="absolute overflow-hidden right-0 mt-2 w-[150px] bg-white border  rounded-lg shadow-lg hidden"
                    >
                      {user?.userType === "publisher" ? (
                        <Link
                          to="/dashboard"
                          className="block px-4 py-2 text-sm no-underline text-gray-700 hover:bg-gray-200"
                        >
                          Dashboard
                        </Link>
                      ) : (
                        <Link
                          to="/my-billing"
                          className="block px-4 py-2 text-sm no-underline text-gray-700 hover:bg-gray-200"
                        >
                          My Billing
                        </Link>
                      )}
                      <Link
                        to="/account-verification"
                        className="block px-4 py-2 text-sm no-underline text-gray-700 hover:bg-gray-200"
                      >
                        Profile Setting
                      </Link>
                      <Link
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 no-underline hover:bg-gray-200"
                      >
                        Logout
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <MarketPlaceSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default Header;
