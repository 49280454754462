import React from 'react'

const SignUpType = ({ onProceed,signIn }) => {
    return (
        <div className='account-type py-[50px] flex flex-col min-h-screen'>
            <div className='container flex flex-col flex-grow'>
                <div className='flex flex-col flex-grow justify-between'>
                    <div>
                        <div className='logo'>
                            <img src='/assets/images/logo.png' alt='logo' className='w-[158px] mb-4' />
                        </div>
                        <h3 className='text-[20px]'>Let’s Get Started</h3>
                        <p className='text-[13px]'>Already have an acwwcount? <a onClick={signIn} className='blue-color underline-offset-4'>Sign in</a></p>
                        <form>
                            <h3 className='text-[16px] mt-5 mb-3'>Choose Your Account Type</h3>
                            <div className='row gy-4'>
                                <div className='col-sm-6'>
                                    <div className="form-check bg-white shadows p-[14px] rounded-lg">
                                        <input className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]" checked type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label font-[500] text-[13px]" htmlFor="flexRadioDefault1">
                                            I am an advertiser
                                        </label>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form-check bg-white shadows p-[14px] rounded-lg">
                                        <input className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label font-[500] text-[13px]" htmlFor="flexRadioDefault2">
                                            I am a publisher
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='w-full'>
                        <p className='mt-5 text-[13px]'>By proceeding you agree to Koinpr terms and conditions </p>
                        <div className='flex justify-center'>
                            <a href='#' onClick={onProceed} className='bg-black-color px-[20px] no-underline text-center leading-[46px] text-white rounded-lg w-full h-[46px]'>Proceed</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpType
