import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../redux/actions/auth.action";

const WithdrawalOption = ({ userData, initialData, handleNext }) => {
  const [selectedOption, setSelectedOption] = useState("bankTransfer");
  const [bankDetails, setBankDetails] = useState({
    beneficiaryName: userData?.beneficiaryName,
    ibanNo: userData?.ibanNo,
    swiftCode: userData?.swiftCode,
    bankName: userData?.bankName,
  });
  const [walletDetails, setWalletDetails] = useState({
    tokenType: "USDT(TRC20)",
    walletAddress: userData?.walletAddress,
  });
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (initialData?.withdrawalMethod) {
      setSelectedOption(initialData.withdrawalMethod);

      if (initialData.withdrawalMethod === "bankTransfer") {
        setBankDetails({
          beneficiaryName: initialData.beneficiaryName || "",
          ibanNo: initialData.ibanNo || "",
          swiftCode: initialData.swiftCode || "",
          bankName: initialData.bankName || "",
        });
      } else if (initialData.withdrawalMethod === "cryptocurrency") {
        setWalletDetails({
          tokenType: initialData.tokenType || "",
          walletAddress: initialData.walletAddress || "",
        });
      }
    }
  }, [initialData]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleBankDetailsChange = (event) => {
    const { name, value } = event.target;
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWalletDetailsChange = (event) => {
    const { name, value } = event.target;
    setWalletDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData;
    if (selectedOption === "bankTransfer") {
      formData = {
        withdrawalOption: "bank",
        ...bankDetails,
      };
    } else {
      formData = {
        withdrawalOption: "crypto",
        ...walletDetails,
      };
    }
    dispatch(updateFormData(formData));
    handleNext(formData);
  };

  return (
    <div className="withdrawal-option bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
      <h3 className="md:text-[20px] text-base font-[590]">Withdrawal Option</h3>
      <p className="mb-5 col-lg-7 md:text-base text-[13px]">
        We will automatically generate a withdrawal request at the end of every
        month depending on your selected preference.
      </p>

      <div className="mb-5">
        <h3 className="md:text-[20px] font-[590] text-base mb-4">
          Select Your Withdrawal Method
        </h3>
        <div className="sm:flex gap-5">
          <div className="form-check">
            <input
              className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]"
              type="radio"
              name="withdrawalMethod"
              id="bankTransfer"
              value="bankTransfer"
              onChange={handleOptionChange}
              checked={selectedOption === "bankTransfer"}
            />
            <label
              className="form-check-label font-[510]"
              htmlFor="bankTransfer"
            >
              Bank Transfer/SWIFT
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]"
              type="radio"
              name="withdrawalMethod"
              id="cryptocurrency"
              value="cryptocurrency"
              onChange={handleOptionChange}
              checked={selectedOption === "cryptocurrency"}
            />
            <label
              className="form-check-label font-[510]"
              htmlFor="cryptocurrency"
            >
              Cryptocurrency
            </label>
          </div>
        </div>
      </div>

      {selectedOption === "bankTransfer" && (
        <div className="sign-up">
          <h3 className="md:text-[20px] text-base font-[590] mb-4">
            Enter your bank details
          </h3>
          <div className="row">
            <div className="col-md-8">
              <div className="row gy-4">
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="Beneficiary Name"
                    className="sign-input"
                    name="beneficiaryName"
                    value={bankDetails.beneficiaryName}
                    onChange={handleBankDetailsChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="IBAN/Account No."
                    className="sign-input"
                    name="ibanNo"
                    value={bankDetails.ibanNo}
                    onChange={handleBankDetailsChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="SWIFT Code"
                    className="sign-input"
                    name="swiftCode"
                    value={bankDetails.swiftCode}
                    onChange={handleBankDetailsChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="Bank Name"
                    className="sign-input"
                    name="bankName"
                    value={bankDetails.bankName}
                    onChange={handleBankDetailsChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "cryptocurrency" && (
        <div className="sign-up">
          <h3 className="md:text-[20px] text-base font-[590] mb-4">
            Enter your wallet details
          </h3>
          <div className="row">
            <div className="col-md-8">
              <div className="row gy-4">
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="Token Type"
                    className="sign-input"
                    name="tokenType"
                    disabled="true"
                    value={walletDetails.tokenType}
                    onChange={handleWalletDetailsChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="Wallet Address"
                    className="sign-input"
                    name="walletAddress"
                    value={walletDetails.walletAddress}
                    onChange={handleWalletDetailsChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        className="bg-black-color px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? "Submitting..." : "Next"}
      </button>
      {error && <p className="text-danger">{error}</p>}
      <p className="mb-0 col-lg-8 mt-2 md:text-base text-[13px]">
        We will automatically generate a withdrawal request at the end of every
        month depending on your selected preference.
      </p>
    </div>
  );
};

export default WithdrawalOption;
