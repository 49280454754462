import Card from "../card";
import { Link } from "react-router-dom";

const Widget = ({ number, title, bgcolor }) => {
  return (
    <Card className={`${bgcolor}  rounded-[16px]`}>
      <p className="rounded-[16px] h-[112px] flex flex-col justify-center">
        <div className="ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm text-sm font-[590] text-black">{title}</p>
        </div>
        <div className="ml-[18px] flex text-[24px] text-black font-[590] w-auto flex-row items-center">
          {number}
        </div>
      </p>
    </Card>
  );
};

export default Widget;
