import React, { useState, useRef, useEffect } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../redux/actions/auth.action";
import { BASEURL } from "../../baseUrl";
import axios from "axios";

const UploadDocuments = ({ userData, handleNext }) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Choose document type");
  const [selectedId, setSelectedId] = useState("");
  const [files, setFiles] = useState([]);
  const [docs, setDocs] = useState([]);
  const [errors, setErrors] = useState({});
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { loading, error, userType } = useSelector(
    (state) => state.authentication
  );

  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchDocsType();
  }, []);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const fetchDocsType = async () => {
    try {
      const response = await axios.get(`${BASEURL}/documents`, {
        params: {
          status: "active",
          // for: userType,
          for: "advertiser",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setDocs(response.data.documents);
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };

  const handleFiles = (newFiles) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...Array.from(newFiles).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const renderFiles = () =>
    files.map((file) => (
      <div
        key={file.name}
        className="file-item d-flex items-center justify-between mb-2 bg-[#F1F8FF] p-3 rounded-lg"
      >
        <p className="file-name mb-0 d-flex items-center gap-3">
          <FaRegFileAlt />
          {file.name}
        </p>
        <button
          onClick={() => removeFile(file)}
          className="remove-button text-[#FF543E] text-xl"
        >
          <RiDeleteBin6Line />
        </button>
      </div>
    ));

  const validate = () => {
    const newErrors = {};
    if (!selectedId) newErrors.documentTypeId = "Document type is required";
    if (files.length === 0) newErrors.files = "At least one file is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("documentTypeId", selectedId);
    files.forEach((file) => {
      formData.append("files", file);
    });

    // Debugging: Log FormData entries with more details

    formData.forEach((value, key) => {
      if (key === "files") {
        console.log(`File: ${value.name}, Size: ${value.size}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    });

    dispatch(updateFormData({ files, documentTypeId: selectedId })); // Dispatch action to update Redux state
    handleNext(); // Proceed to the next step
  };

  return (
    <div>
      <div className="identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
        <h3 className="md:text-[20px] text-base font-[590]">
          Upload Documents
        </h3>
        <p className="mb-5 col-lg-7 md:text-base text-[13px]">
          Please upload the required documents below in order to validate your
          Identification Details.
        </p>

        <div className="mb-5 col-lg-6">
          <div className="dropdown p-[14px] rounded-lg bg-white shadows">
            <div
              onClick={() => setIsActive(!isActive)}
              className="dropdown-btn flex items-center justify-between"
            >
              <span className="font-[510] md:text-base text-[13px]">
                {selected}
              </span>
              <span
                className={
                  isActive ? "fas fa-chevron-up" : "fas fa-chevron-down"
                }
              />
            </div>
            <div
              className="dropdown-menu left-0 mt-2 w-full"
              style={{ display: isActive ? "block" : "none" }}
            >
              {Array.isArray(docs) &&
                docs?.map((item) => (
                  <div
                    onClick={(e) => {
                      const dataId = e.target.dataset.id;
                      setSelectedId(dataId);
                      setSelected(e.target.textContent);
                      setIsActive(!isActive);
                    }}
                    className="dropdown-item"
                    data-Id={item?._id}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
          {errors.documentTypeId && (
            <p className="text-danger">{errors.documentTypeId}</p>
          )}
        </div>

        <div className="mt-4 col-lg-6">
          <h3 className="md:text-[20px] text-base font-[590] mb-3">
            Upload File
          </h3>
          <div
            className="image_upload"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              border: "2px dashed #DBDBDB",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple
              style={{ display: "none" }}
              onChange={(e) => handleFiles(e.target.files)}
            />
            <div onClick={onButtonClick} className="font-[300]">
              <img
                src="/assets/images/account/upload.png"
                className="mx-auto mb-3 w-[90px]"
                alt="upload"
              />
              <p className="md:text-base text-[13px] font-[590] mb-1">
                Browse Files to upload
              </p>
              <div className="text-[12px]">
                Max file size 5 MB. Supported file types: png, jpeg, pdf, doc.
              </div>
            </div>
          </div>
          {errors.files && <p className="text-danger">{errors.files}</p>}
          <div className="file-preview-container mt-3">{renderFiles()}</div>
        </div>

        <button
          className="bg-black-color px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Uploading..." : "Next"}
        </button>
        {error && <p className="text-danger">{error}</p>}
        <p className="mb-0 col-lg-8 mt-2 md:text-base text-[13px]">
          Please make sure that the details you enter here matches the documents
          you will be providing for verification.
        </p>
      </div>
    </div>
  );
};

export default UploadDocuments;
