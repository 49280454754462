import React from 'react'
import PackageDashboard from './PackageDashboard'

const Index = () => {
    return (
        <div className='sticky-header'>
            <div id="main-wrapper" className="main-wrapper">
                <PackageDashboard />
            </div>
        </div>
    )
}

export default Index
