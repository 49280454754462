import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOfferingDataForm,
  removeAllOfferingData,
} from "../../../redux/actions/offering.action";
import axios from "axios";
import { BASEURL } from "../../../baseUrl";
import Content from "../category/Content";
import Advertisement from "../category/Advertisement";
import TwitterInfluencers from "../category/TwitterInfluencers";
import TelegrameInfluncers from "../category/TelegramInfluencers";
import YoutubeInfluncers from "../category/YouTubeInfluencers";
import InstagramInfluncers from "../category/InstagramInfluencers";
import TikTokInfluncers from "../category/TikTokInfluencers";
import IcoListing from "../category/IcoListing";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { authApi } from "../../../api";

const AddOfferingDetails = ({ handleNext, categoryId, category }) => {
  const [offeringCategoryLs, setOfferingCategoryLs] = useState([]);
  const dispatch = useDispatch();
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().__default
  );
  const { offeringData } = useSelector((state) => state.offerings);

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    const fetchOfferingCategoryLs = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${BASEURL}/offering-categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOfferingCategoryLs(response.data.offeringCategoryList);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOfferingCategoryLs();
  }, []);

  const handleFormData = async (val, name = "") => {
    try {
      const {
        data: { offeringList },
      } = await authApi.get(`/offerings/${val}`);

      if (offeringList) {
        const newValues = {
          ...offeringList,
          ...offeringList.basicDetails,
          logo: offeringList.basicDetails?.["Website LOGO"],
          files: offeringList.basicDetails?.["files[]"],
          offeringCategoryId: offeringList?.offeringCategoryId?._id,
          category: offeringList?.offeringCategoryId?.name,
          contentOfferings: offeringList?.contentOfferings?.map(
            (item, index) => {
              console.log(offeringList?.contentOfferings[index]?.features);
              return {
                ...item,
                offeringName:
                  offeringList?.contentOfferings[index]?.typeId?.name,
                typeId:
                  offeringList?.contentOfferings[index]?.typeId?._id ||
                  offeringList?.contentOfferings[index]?.typeId ||
                  "",
                features:
                  offeringList?.contentOfferings[index]?.features?.map((i) => {
                    return {
                      featureType: i?.featureType?._id || i?.featureType,
                      checked: true,
                      value: i?.value,
                    };
                  }) || [],
                featuresPreview:
                  offeringList?.contentOfferings[index]?.features?.map((i) => {
                    return {
                      featureType: i?.featureType?._id || i?.featureType,
                      id: i?.featureType?._id || i?.featureType,
                      name: i?.featureType?.name,
                      checked: true,
                      value: i?.value,
                    };
                  }) || [],
              };
            }
          ),
        };

        setFormValues(newValues);

        dispatch(addOfferingDataForm(newValues));
      } else {
        setFormValues({
          offeringCategoryId: val || "",
          category: name || "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (categoryId) {
      handleFormData(categoryId, category);
    }
  }, [categoryId, dispatch]);

  return (
    <div className="AddOffering_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
      <Formik
        initialValues={{
          ...(offeringData?.basicDetails || {}),
          offeringCategoryId:
            offeringData?.offeringCategoryId || categoryId || "",
          category: offeringData?.category || category || "",
          logo: offeringData?.logo || offeringData?.["Website LOGO"]?.[0] || "",
          files: offeringData?.files || offeringData?.["files[]"] || "",
          socialLinks: offeringData?.basicDetails?.socialLinks ?? [
            {
              name: "",
              link: "",
            },
          ],
          ...(formValues || {}), 
        }}
        validationSchema={validationSchema}
        onSubmit={(
          { offeringCategoryId, category, files, logo, _id, ...rest },
          { setSubmitting }
        ) => {          
          const data = {
            offeringCategoryId,
            category: category || offeringCategoryId?.name,
            basicDetails: rest,
            "Website LOGO": logo,
            files: files,
            _id: _id,
          };
          dispatch(addOfferingDataForm(data));
          setSubmitting(false);
          handleNext(data);
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          resetForm,
          setValues,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <h3 className="text-[20px] font-[590] mb-4">Add Offering</h3>
              </div>
              <div className="col-lg-12">
                <div className="dropdown rounded-lg bg-white mb-3">
                  <div className="w-[100%]">
                    <label
                      htmlFor="offeringCategoryId"
                      className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                    >
                      Select Category
                    </label>{" "}
                    <br />
                    <select
                      name="offeringCategoryId"
                      className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
                      id="offeringCategoryId"
                      onChange={(e) => {
                        dispatch(removeAllOfferingData());
                        resetForm();
                        setFieldValue(
                          "category",
                          e.target.selectedOptions[0].getAttribute("data-id")
                        );
                        handleFormData(
                          e.target.value,
                          e.target.selectedOptions[0].getAttribute("data-id")
                        );
                        setFieldValue("offeringCategoryId", e.target.value);
                      }}
                      value={
                        values.offeringCategoryId?._id ||
                        values.offeringCategoryId ||
                        ""
                      }
                    >
                      <option
                        className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                        disabled
                        value=""
                      >
                        Choose Category
                      </option>
                      {offeringCategoryLs?.map((item, index) => (
                        <option
                          key={item?._id || index}
                          value={item._id}
                          data-id={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.offeringCategoryId && (
                      <span className="text-sm text-red-500">
                        {errors.offeringCategoryId}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* Render appropriate components based on category */}
              {values?.category === "Content Distribution" ||
              values?.offeringCategory?.name === "Content Distribution" ? (
                <Content
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "Ads" ||
                values?.offeringCategory?.name === "Ads" ? (
                <Advertisement
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "Twitter Influencers" ||
                values?.offeringCategory?.name === "Twitter Influencers" ? (
                <TwitterInfluencers
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "Telegram Influencers" ||
                values?.offeringCategory?.name === "Telegram Influencers" ? (
                <TelegrameInfluncers
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "YouTube Influencers" ||
                values?.offeringCategory?.name === "YouTube Influencers" ? (
                <YoutubeInfluncers
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "Instagram Influencers" ||
                values?.offeringCategory?.name === "Instagram Influencers" ? (
                <InstagramInfluncers
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "TikTok Influencers" ||
                values?.offeringCategory?.name === "TikTok Influencers" ? (
                <TikTokInfluncers
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : values?.category === "ICO Listing" ||
                values?.offeringCategory?.name === "ICO Listing" ? (
                <IcoListing
                  handleFieldValue={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValidationSchema={setValidationSchema}
                  FieldArray={FieldArray}
                />
              ) : (
                ""
              )}

              <div className="mt-[60px]">
                <button
                  type="submit"
                  className="w-full bg-[#0A0A0A] rounded-[8px] py-[15px] px-[20px] text-[590] text-[16px] leading-[19.2px] transition-transform transform active:bg-gray-800   active:scale-95 active:shadow-lg text-[#FFFFFF]"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOfferingDetails;
