import axios from "axios";
import { BASEURL } from "../../baseUrl";

export const ADD_OFFERING_DATA_SUCCESS = "ADD_OFFERING_DATA_SUCCESS";
export const ADD_OFFERING_DATA_FAILURE = "ADD_OFFERING_DATA_FAILURE";
export const ADD_OFFERING_DATA_REQUEST = "ADD_OFFERING_DATA_REQUEST";
export const FETCH_OFFERING_DATA_SUCCESS = "FETCH_OFFERING_DATA_SUCCESS";
export const FETCH_OFFERING_DATA_FAILURE = "FETCH_OFFERING_DATA_FAILURE";
export const FETCH_OFFERING_DATA_REQUEST = "FETCH_OFFERING_DATA_REQUEST";

export const UPDATE_OFFERING_DATA_FORM = "UPDATE_OFFERING_DATA_FORM";
export const REMOVE_OFFERING_DATA_FORM = "REMOVE_OFFERING_DATA_FORM";
export const REMOVE_ALL_OFFERING_ITEM = "REMOVE_ALL_OFFERING_ITEM";

export const addOfferingDataForm = (data) => ({
  type: "UPDATE_OFFERING_DATA_FORM",
  payload: data,
});
export const removeOfferingDataForm = () => ({
  type: "REMOVE_OFFERING_DATA_FORM",
});
export const removeAllOfferingData = () => ({
  type: "REMOVE_ALL_OFFERING_ITEM",
});

export const addOfferingData = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "ADD_OFFERING_DATA_REQUEST" });
    try {
      const { offeringData } = getState().offerings;
      const token = localStorage.getItem("token");

      // Create a FormData object
      const formData = new FormData();

      // Append all fields to the FormData object
      formData.append("category", offeringData.category);
      formData.append("offeringCategoryId", offeringData.offeringCategoryId);
      formData.append("websiteName", offeringData.websiteName);
      formData.append("websiteURL", offeringData.websiteURL);
      formData.append("websiteDescription", offeringData.websiteDescription);
      formData.append("officialEmail", offeringData.officialEmail);
      formData.append("telegramID", offeringData.telegramID);
      formData.append("contentLanguage", offeringData.contentLanguage);
      formData.append("geoLocations", offeringData.geoLocations);
      formData.append("gambling", offeringData.gambling);
      formData.append("adultContent", offeringData.adultContent);
      formData.append("crypto", offeringData.crypto);
      formData.append("Offering", offeringData.Offering);
      formData.append("mediaKitPrice", offeringData.mediaKitprice);
      formData.append(
        "mediaDiscountedKitPrice",
        offeringData.mediaDiscountedKitPrice
      );

      // Append files to FormData
      offeringData.files.forEach((file, index) => {
        formData.append(`files[${index}][name]`, file.name);
        formData.append(`files[${index}][preview]`, file.preview);
        formData.append(`files[${index}][size]`, file.size);
        formData.append(`files[${index}][type]`, file.type);
      });

      // Append features
      offeringData.features.forEach((feature, index) => {
        formData.append(`features[${index}]`, feature);
      });

      const response = await axios.post(`${BASEURL}/offerings`, formData, {
        params: {
          status: "created",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: ADD_OFFERING_DATA_SUCCESS,
        payload: response.data.offering, // Adjusted to match the response structure
      });
      window.location.href = "/Dashboard";
    } catch (error) {
      dispatch({
        type: ADD_OFFERING_DATA_FAILURE,
        error: error.message,
      });
    }
  };
};
