import { Wrapper } from "../layout";
// import HomePage from "../Components/home-page";
import AddOffering from "../Components/Offering/Add-Offering";

export default function Home() {
  return (
    <Wrapper>
      <AddOffering />
    </Wrapper>
  );
}
