import React from 'react'
import PackageHeader from './PackageHeader'
import LivePackage from '../create-package/LivePackage'

const PackageDashboard = () => {
    return (
        <div className="flex dashboard">
            <div className="container-fluid !pl-[280px] !pr-[20px] py-[24px]">
                <PackageHeader/>
                <div>
                    <LivePackage/>
                </div>
            </div>
        </div>
    )
}

export default PackageDashboard
