import axios from "axios";
import { useEffect, useRef, useState } from "react";

// API endpoints
const COUNTRIES_API = "https://restcountries.com/v3.1/all";
const STATES_VIA_COUNTRY =
  "https://countriesnow.space/axios/v0.1/countries/states";
const CITIES_VIA_STATE =
  "https://countriesnow.space/axios/v0.1/countries/state/cities";

// Custom hook for handling geographic data
const useGeo = () => {
  // State for countries, states, and cities
  const [countries, setCountries] = useState(null);
  const [lang, setLang] = useState(null);
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);

  // State for tracking user selection
  const [selection, setSelection] = useState(null);
  const [pipeline, setPipeline] = useState(null);

  // Fetch countries data on component mount
  useEffect(() => {
    (async function fetchCountries() {
      try {
        const { data: countries } = await axios.get(COUNTRIES_API);

        // Sort countries alphabetically by name
        const sortedCountries = countries.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );

        setCountries(sortedCountries);

        const seenIds = new Set();
        setLang(
          sortedCountries.flatMap((item) => {
            return Object.keys(item.languages || {})
              .map((key) => {
                const languageName = item.languages[key];
                const languageObj = {
                  _id: languageName,
                  name: languageName,
                  value: languageName,
                };

                // Check if the _id already exists in the Set
                if (!seenIds.has(languageName)) {
                  seenIds.add(languageName);
                  return languageObj;
                }

                // Return null or filter this out if _id exists
                return null;
              })
              .filter(Boolean); // Removes nulls from the array
          })
        );
      } catch (error) {
        setCountries([]);
        console.error("Error fetching countries:", error);
      }
    })();
  }, []);

  // Fetch states or cities based on user selection
  useEffect(() => {
    // Check if selection is available
    if (!selection) return;

    (async function fetchData() {
      try {
        // Fetch cities if state is selected
        if (selection.states) {
          const KEY = selection.key;
          let cities = await Promise.all(
            selection.states.map(async (state, i) => {
              const {
                data: { data },
              } = await axios.post(CITIES_VIA_STATE, {
                country: selection.countries[i],
                state: state[KEY],
              });
              return data;
            })
          );
          cities = cities?.flat();

          if (cities?.length <= 0) return setCities([]);
          setCities(cities);
        }

        // Fetch states if country is selected
        else if (selection.countries) {
          const KEY = selection.key;
          const data = await Promise.all(
            selection.countries.map(async (item) => {
              const {
                data: { data },
              } = await axios.post(STATES_VIA_COUNTRY, {
                country: item[KEY],
              });
              return [data?.states, data?.name];
            })
          );

          const states = data?.reduce((acc, item) => {
            // add country to their state
            acc.push(...item[0].map((s) => ({ ...s, country: item[1] })));
            return acc;
          }, []);

          if (states?.length <= 0) return setStates([]);
          setStates(states);
        } else {
          setStates([]);
          setCities([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [selection]);

  useEffect(() => {
    if (!pipeline) return;

    (async () => {
      try {
        const countries = pipeline.countries;
        const sstates = pipeline.states;

        const data = await Promise.all(
          countries.map(async (item) => {
            const {
              data: { data },
            } = await axios.post(STATES_VIA_COUNTRY, {
              country: item,
            });
            return [data?.states, data?.name];
          })
        );
        const selectedStates = [];
        const states = data?.reduce((acc, item) => {
          const format = item[0]?.map((s) => {
            if (sstates.some((sn) => sn === s?.name)) {
              selectedStates.push({ ...s, country: item[1] });
            }
            return { ...s, country: item[1] };
          });

          // add country to their state
          acc.push(...format);
          return acc;
        }, []);
        setStates(states);

        let cities = await Promise.all(
          selectedStates.map(async (state) => {
            const {
              data: { data },
            } = await axios.post(CITIES_VIA_STATE, {
              country: state.country,
              state: state.name,
            });
            return data;
          })
        );
        cities = cities?.flat();

        setCities(cities);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [pipeline]);

  // Expose countries, states, cities and setSelection function to the component using this hook
  return { countries, lang, states, cities, setSelection, setPipeline };
};

export default useGeo;
