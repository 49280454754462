import React, { useState, useEffect } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { FaMoneyBillWave, FaRegFileAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import ContentOffering from "./ContentOffering";
import { addOfferingDataForm } from "../../../redux/actions/offering.action";
import { authApi } from "../../../api";
import toast from "react-hot-toast";

const AddcontentOffering = ({
  handleNext,
  handleBack,
  dataIndex,
  setDataIndex,
  setEditMode: propEdit,
}) => {
  const [offeringsTypeList, setOfferingsTypeList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);

  const dispatch = useDispatch();
  const { offeringData } = useSelector((state) => state.offerings);
  const handleFetchFeatures = async (val, idx) => {
    try {
      const { data } = await authApi.get(`/features`, {
        params: {
          offeringTypeList: val ?? "",
        },
      });

      setFeaturesList((prev) => {
        const updatedList = [...(prev || [])];
        updatedList[idx] = data?.featureList;
        return updatedList;
      });
    } catch (error) {
      console.log(error, "Error while getting Data");
    }
  };

  useEffect(() => {
    const fetchOfferingData = async () => {
      try {
        const { data } = await authApi.get(
          `/offering-categories/${
            offeringData.offeringCategoryId?._id ||
            offeringData.offeringCategoryId
          }`
        );
        setOfferingsTypeList(data.offeringCategory?.offeringTypeList || []);
        // setfeaturesList(data.offeringCategory?.features || []);
      } catch (error) {
        console.log(error, "Error while getting Data");
      }
    };
    fetchOfferingData();
  }, [offeringData]);

  // const handleExample = (e) => {
  //   e.preventDefault();
  //   const offeringData = new FormData();
  //   offeringData.append("documentType", selected);
  //   files.forEach((file) => {
  //     offeringData.append("files", file);
  //   });
  //   const exampleDetails = {
  //     linkTitle: e.target.elements.linkTitle.value,
  //     link: e.target.elements.link.value,
  //   };
  //   dispatch(addOfferingDataForm(exampleDetails));
  //   setUpdateUsershow(false);
  // };

  // const handleAddNewOfferings = (e) => {
  //   e.preventDefault();
  //   const contentDetails = {
  //     Offering: e.target.elements.Offering.value,
  //     mediaKitprice: e.target.elements.mediaKitprice?.value,
  //     mediaDiscountedKitPrice: e.target.elements.mediaDiscountedKitPrice?.value,
  //     Feature: [e.target.elements.Feature?.value],
  //   };

  //   dispatch(addOfferingDataForm(contentDetails));
  //   setClickCount(clickCount + 1);
  //   setSelectedOffering(""); // Reset selectedOffering
  //   setFeatures([]);
  //   handleNext();
  // };

  const handleDeleteOffering = (index) => {
    const newOfferingData = offeringData?.contentOfferings?.filter(
      (_, i) => i !== index
    );
    dispatch(addOfferingDataForm({ contentOfferings: newOfferingData }));
  };

  return (
    <div className="AddOffering_details bg-white border-1 border-[#DBDBDB] rounded-lg p-3">
      <div className="flex flex-col gap-4">
        <h3 className="text-[20px] font-[590] mb-0">Add Content Offerings</h3>
        {Array.isArray(offeringData?.contentOfferings) &&
          offeringData?.contentOfferings?.length > 0 &&
          offeringData?.contentOfferings?.map((_, index) =>
            _?.typeId ? (
              <div key={index}>
                {dataIndex > -1 && dataIndex === index ? (
                  <ContentOffering
                    index={dataIndex}
                    offeringList={offeringsTypeList}
                    featuresList={featuresList?.[index] ?? []}
                    setFeaturesList={setFeaturesList}
                    handleFetchFeatures={handleFetchFeatures}
                    handleDeleteOffering={handleDeleteOffering}
                    handleDataEditClose={() => setDataIndex(-1)}
                  />
                ) : (
                  <div className="bg-[#F8F8F8] border-[1px] border-[#C4C4C4] rounded-[8px] p-[20px]">
                    <div className="flex justify-between">
                      <h4 className="font-[590] text-[20px] leading-[23.87px] text-[#464646] ">
                        {_?.offeringName || _?.typeId?.name}
                      </h4>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setDataIndex(index);
                            // handleFetchFeatures(_?.typeId?.name);
                          }}
                          className="flex items-center text-[#3772FF] font-[510] text-[16px] leading-[19.2px] gap-2 "
                        >
                          <RiEdit2Line /> Edit
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-3 mt-[14px]">
                      <div className="metric-info gap-2 !py-[12px] !px-[19px] rounded-[4px] !bg-[#EDEDED]">
                        {/* <FaMoneyBillWave /> */}
                        <img
                          src="/assets/images/account/payments.png"
                          alt="payment"
                        />
                        <span className="font-[510] text-[12px] leading-[20.04px]">
                          Media Kit Price: {_?.mediaKitprice}
                        </span>
                      </div>
                      <div className="metric-info gap-2 !py-[12px] !px-[19px] rounded-[4px] !bg-[#EDEDED]">
                        {/* <FaMoneyBillWave /> */}
                        <img
                          src="/assets/images/account/payments.png"
                          alt="payment"
                        />
                        <span className="font-[510] text-[12px] leading-[20.04px]">
                          Discounted Price: {_?.mediaDiscountedKitPrice}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 bg-[#EDEDED] py-[8px] px-[19px] border-[1px] border-[#D6D6D6] rounded-[3px]">
                      <p className="font-[510] text-[12px] leading-[14.32px] text-[#000000]">
                        Features
                      </p>

                      <div className="flex flex-wrap gap-3 mt-[14px]">
                        {Array.isArray(_?.featuresPreview) &&
                          _?.featuresPreview?.length > 0 &&
                          _?.featuresPreview?.map((item, index) => {
                            if (item?.name && item?.checked) {
                              return (
                                <div
                                  key={index}
                                  className="metric-info gap-2 !py-[2px] !px-[10px] rounded-[4px] !bg-[#FFFFFF]"
                                >
                                  <span className="font-[510] text-[14px] leading-[21px]">
                                    {item.name} : {item?.value}
                                  </span>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </div>
                    {_?.example && Object.keys(_?.example)?.length > 0 && (
                      <div className="mt-3 bg-[#EDEDED] py-[8px] px-[19px] border-[1px] border-[#D6D6D6] rounded-[3px]">
                        <p className="font-[510] text-[12px] leading-[14.32px] text-[#000000] mb-3">
                          Example
                        </p>
                        <div className="bg-white py-2 mb-2">
                          <div
                            key={index}
                            className=" flex gap-4  !px-[10px] rounded-[4px] "
                          >
                            <p className="font-bold w-[40%] !mb-1">
                              Social Media Title
                            </p>
                            <p className="font-bold !mb-1">Social Media link</p>
                          </div>
                          {_?.example?.links?.length > 0 &&
                            _?.example?.links?.map((link, index) => (
                              <div
                                key={index}
                                className=" flex gap-4 !px-[10px] rounded-[4px] "
                              >
                                <p className="!mb-1 w-[40%]">{link?.title}</p>
                                <p className="!mb-1">{link?.value}</p>
                              </div>
                            ))}
                        </div>

                        {(offeringData?.basicDetails?.files || [])?.map(
                          (file) => (
                            <div
                              key={file.name}
                              className="file-item d-flex items-center justify-between mb-2 bg-[#F1F8FF] p-3 rounded-lg"
                            >
                              <p className="file-name mb-0 d-flex items-center gap-3">
                                <FaRegFileAlt />
                                {file.name}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null
          )}

        {Array.isArray(offeringData?.contentOfferings) &&
          offeringData?.contentOfferings?.length > 0 &&
          offeringData?.contentOfferings?.map((_, index) =>
            !_?.typeId ? (
              <ContentOffering
                key={index}
                index={index}
                handleDeleteOffering={handleDeleteOffering}
                offeringList={offeringsTypeList}
                featuresList={featuresList?.[index] ?? []}
                setFeaturesList={setFeaturesList}
                handleFetchFeatures={handleFetchFeatures}
              />
            ) : null
          )}

        <div className="text-start">
          <button
            type="submit"
            className="text-[#0A0A0A] py-2 px-3 border-[1px] rounded-[5px] border-[#0A0A0A]"
            onClick={() => {
              dispatch(
                addOfferingDataForm({
                  contentOfferings: [
                    ...(offeringData?.contentOfferings || []), // spread the existing contentOfferings if they exist
                    {}, // add a new empty object
                  ],
                })
              );
            }}
          >
            + Add new offerings
          </button>
        </div>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={handleBack}
            className="text-[#0A0A0A] text-[18px] px-8 py-2 rounded-[5px] bg-[#DBDBDB]"
          >
            Back
          </button>
          <button
            onClick={() => {
              let isValid = true;
              offeringData?.contentOfferings?.forEach((element) => {
                if (!element?.typeId) {
                  isValid = false;
                  toast.error("Please Fill Required Fields");
                  return;
                }
              });
              if (isValid) {
                handleNext(offeringData);
              }
            }}
            className="text-[#FFFFFF] text-[18px] px-8 py-2 rounded-[5px] bg-[#0A0A0A] transition-transform transform active:bg-gray-800   active:scale-95 active:shadow-lg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddcontentOffering;
