import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP } from "../../redux/actions/auth.action";
import { useNavigate } from "react-router-dom";
import { authApi } from "../../api";
import { toast } from "react-hot-toast";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [timer, setTimer] = useState(60); // Timer state
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const { isEmailVerified, error } = useSelector(
    (state) => state.authentication
  );
  const [isVisible, setIsVisible] = useState(true);
  const timerRef = useRef();

  const handleInputChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);

    if (e.target.value !== "" && index < 5) {
      setTimeout(() => {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }, 0);
    }
  };

  const handleVerifyClick = () => {
    dispatch(verifyOTP(code.join(""), user?.email));
  };

  useEffect(() => {
    if (isEmailVerified) {
      setIsVisible(false);
      navigate("/Dashboard");
    }
  }, [isEmailVerified, navigate]);

  // Timer effect
  useEffect(() => {
    if (timer > 0) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [timer]);

  const handleResendCode = async () => {
    setLoading(true);
    try {
      await authApi.post("/auth/resend-verification-code", {
        email: user?.email,
      });
      toast.success("Verification code sent");
      setTimer(60); // Reset timer on resend
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
      setLoading(false);
    }
  };

  const handleEditEmail = async () => {
    try {
      setLoading1(true);
      const response = await authApi.patch("/auth/editEmail", {
        userId: user?._id,
        email: newEmail,
      });
      localStorage.setItem("user", JSON.stringify(response?.data?.updatedUser));
      toast.success("Email Updated");
      setLoading1(false);
      setShowEditEmail(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
      setLoading1(false);
    }
  };

  return (
    isVisible && (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-[#93939399]">
        <div className="bg-white p-4 rounded-3xl w-[600px] mx-2">
          <div className="logo mb-3">
            <a href="/">
              <img
                src="/assets/images/logo.png"
                className="w-[100px]"
                alt="logo"
              />
            </a>
          </div>

          {showEditEmail ? (
            <div className="text-center">
              <h3 className="text-[20px] font-[590]">Enter New Email</h3>
              <div className="my-2">
                <input
                  type="email"
                  value={newEmail}
                  placeholder="Enter Your Email"
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="w-[270px] h-[48px] m-2 rounded-lg border border-[#DBDBDB] text-center"
                />
              </div>
              <button
                onClick={handleEditEmail}
                disabled={loading1}
                type="button"
                className="px-5 bg-black-color text-white inline-block py-2 no-underline rounded-lg"
              >
                {loading1 ? "Submitting..." : "Submit"}
              </button>
            </div>
          ) : (
            <div className="text-center">
              <h3 className="text-[24px] font-[590]">
                Verify Your Email Address
              </h3>
              <div>We've sent an e-mail to {user?.email}</div>
              <div>with a 6 digits verification code, </div>
              <div>please provide it below.</div>
              <div className="my-4">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    maxLength="1"
                    placeholder="X"
                    className="w-[51px] h-[48px] m-2 rounded-lg border border-[#DBDBDB] text-center"
                  />
                ))}
              </div>
              <button
                onClick={handleVerifyClick}
                className="w-[270px] bg-black-color text-white inline-block py-2 no-underline rounded-lg"
              >
                Verify Me
              </button>
              {error && <p className="mt-3 text-red-500">{error}</p>}
              <p className="mt-3">
                Oops! Email is not correct{" "}
                <button
                  onClick={() => {
                    setShowEditEmail(true);
                  }}
                  className="text-[#CC0000] cursor-pointer no-underline font-[510]"
                >
                  Edit Email
                </button>
              </p>
              <p className="text-[#5E5E5E]">
                {timer > 0 ? (
                  <>
                    Resend Code in {timer} sec
                  </>
                ) : (
                  <button
                    className="text-[#0A0A0A] font-[510] cursor-pointer"
                    onClick={handleResendCode}
                    disabled={loading}
                  >
                    Resend Code
                  </button>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default VerifyEmail;
