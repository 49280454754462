import { useState } from "react";
import { authApi } from "../api";
import { toast } from "react-hot-toast";
import useCarts from "./useCarts";

export default function useAddCart() {
  const [loading, setLoading] = useState(false);
  const { carts, setCarts } = useCarts();

  const addToCart = async (type, itemId) => {
    try {
      setLoading(true);

      // Prepare the new item
      const newItem = { id: itemId, uploadedDoc: "" };

      if (carts?._id) {
        const existingItems = Array.isArray(carts[type]) ? carts[type] : [];
        const duplicate = existingItems.some((item) => item.id === itemId);

        if (duplicate) {
          toast.error(`${type} already present in the cart`);
          return;
        }
        const updatedCart = {
          ...carts,
          [type]: [...existingItems, newItem],
        };

        const response = await authApi.patch(
          `/carts/${carts?._id}`,
          updatedCart
        );
        toast.success("Added to cart ");
        setCarts(response?.data?.cart);
      } else {
        const payload = {
          [type]: [newItem],
        };

        const response = await authApi.post("/carts/", payload);
        toast.success("Added to cart ");
        setCarts(response?.data?.cart);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const addToCartWithAddOns = async (items) => {
    try {
      setLoading(true);

      // Iterate through each type and its associated items
      for (const [type, ids] of Object.entries(items)) {
        const itemIds = Array.isArray(ids) ? ids : [ids]; // Ensure we have an array of IDs

        for (const itemId of itemIds) {
          // Prepare the new item
          const newItem = { id: itemId, uploadedDoc: "" };

          if (carts?._id) {
            const existingItems = Array.isArray(carts[type]) ? carts[type] : [];
            const duplicate = existingItems.some((item) => item.id === itemId);

            if (duplicate) {
              continue; // Skip to the next item if it's a duplicate
            }

            const updatedCart = {
              ...carts,
              [type]: [...existingItems, newItem],
            };

            // Send the patch request to update the cart
            const response = await authApi.patch(
              `/carts/${carts?._id}`,
              updatedCart
            );

            setCarts(response?.data?.cart);
          } else {
            const payload = {
              [type]: [newItem],
            };

            // // Send the post request to create a new cart
            const response = await authApi.post("/carts/", payload);
            setCarts(response?.data?.cart);
          }
        }
      }
      toast.success(`Added to cart`);
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again"
      );
    } finally {
      setLoading(false);
    }
  };

  return { addToCart, addToCartWithAddOns, loading };
}
