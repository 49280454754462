import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { MdError } from "react-icons/md";
import { authApi } from "../../api";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";

const Billing = ({ total, cartId }) => {
  const stripePromise = loadStripe(
    "pk_test_51PyVA0IiNO6W2oN274ohXwP7zw2eNnKDz0IPOeoRRcGPow4B3lvSLgnC6q4sd3TgcjFkF5wJFZAzHKtU0wezUahC005LsGsq7n"
  );
  const [billingInfo, setBillingInfo] = useState({
    name: "",
    email: "",
    telegramId: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const calculateTax = (taxPercent) => {
    const tax = total * (taxPercent / 100);
    return tax.toFixed(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
  };
  const validateForm = () => {
    const requiredFields = [
      "name",
      "email",
      "telegramId",
      "country",
      "city",
      "state",
      "pinCode",
    ];
    for (let field of requiredFields) {
      if (!billingInfo[field]) {
        toast.error(`Please fill in your ${field}.`);
        return false;
      }
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(billingInfo.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (billingInfo.pinCode.length !== 6) {
      toast.error("Please enter a valid postal code.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    const taxAmount = parseFloat(calculateTax(18));

    const subTotal = total + taxAmount;
    try {
      const response = await authApi.post("/transactions", {
        cartId,
        amount: subTotal.toFixed(2),
        billingInfo,
        paymentMethod,
      });
      if (response.data) {
        const stripe = await stripePromise;
        console.log({ stripe });
        if (paymentMethod === "stripe") {
          const { txnId } = response.data?.transaction;

          const { error } = await stripe.redirectToCheckout({
            sessionId: txnId,
          });
          if (error) {
            toast.error(
              error.message || "Something went wrong with Stripe checkout"
            );
          }
        } else if (paymentMethod === "coingate") {
          window.location.href = response.data.paymentURL;
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went wrong");
      console.error("Error submitting order:", error);
    }
  };

  return (
    <div className="md:bg-white md:border-[1px] border-[#DBDBDB] rounded-lg">
      <div className="md:p-6 md:border-b border-[#DBDBDB]">
        <div className="d-lg-block d-none">
          <div className="bg-[#FFF3C7] mb-4 d-flex gap-2 rounded border-l-2 border-[#EEC800] p-3">
            <span className="font-[590]">Note:</span>
            <p className="text-[#5E5E5E] mb-0">
              Once you submit your order and confirm payment, the distribution
              process will begin, and one of our team members will reach out to
              you within 1-6 business hours to ensure everything is on track.
            </p>
          </div>
        </div>
        <div>
          <div className="font-[590] mb-3 flex items-center gap-2">
            Enter Billing Details <MdError className="text-[#808080]" />
          </div>
          <div className="row gy-4">
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">Name*</label>
                <input
                  type="text"
                  name="name"
                  value={billingInfo.name}
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">
                  Email ID*
                </label>
                <input
                  type="email"
                  name="email"
                  value={billingInfo.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email ID"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">
                  Telegram ID*
                </label>
                <input
                  type="text"
                  name="telegramId"
                  value={billingInfo.telegramId}
                  onChange={handleInputChange}
                  placeholder="Enter Telegram ID"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">
                  Country*
                </label>
                <input
                  type="text"
                  name="country"
                  value={billingInfo.country}
                  onChange={handleInputChange}
                  placeholder="Country"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">State*</label>
                <input
                  type="text"
                  name="state"
                  value={billingInfo.state}
                  onChange={handleInputChange}
                  placeholder="State"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">City*</label>
                <input
                  type="text"
                  name="city"
                  value={billingInfo.city}
                  onChange={handleInputChange}
                  placeholder="Select City"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="mb-2 text-[#474747] font-[510]">
                  Postal Code*
                </label>
                <input
                  type="number"
                  name="pinCode"
                  value={billingInfo?.pinCode}
                  onChange={handleInputChange}
                  placeholder="Postal Code"
                  className="w-100 rounded-lg p-[10px] border border-[#DBDBDB]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="md:px-6 py-3">
            <p className="font-[590] flex items-center gap-2">
              Check Out <MdError className="text-[#808080]" />
            </p>
            <p>Select Payment Method</p>
            {/* <div className="d-flex items-center gap-2 mb-3">
              <Form.Check
                type="radio"
                id="name"
                value="stripe"
                name="paymentMethod"
                label="Pay via"
                className="text-black"
                checked={paymentMethod === "stripe"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              <img src="/assets/images/MarketPlace/stripe.png" alt="stripe" />
            </div> */}
            <div className="d-flex items-center gap-2">
              <Form.Check
                type="radio"
                id="name1"
                name="paymentMethod"
                value="coingate"
                label="Pay via"
                checked={paymentMethod === "coingate"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="text-black"
              />
              <img src="/assets/images/MarketPlace/coin.png" alt="coin" />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="bg-[#F1F4FF] md:px-6 px-3 py-3">
            <p className="flex text-[#5E5E5E] justify-between items-center mb-1">
              Sub total <span>$ {total?.toFixed(2)}</span>
            </p>
            <p className="flex text-[#5E5E5E] justify-between items-center">
              Tax <span>$ {calculateTax(18)}</span>
            </p>
            {/* <p className='flex text-[#5E5E5E] justify-between items-center'>Add-ons <span>$ 5000</span></p> */}
            <div className="border-t border-[#DBDBDB]">
              <p className="flex justify-between items-center font-[590] mt-3">
                Total
                <span>
                  $ {(total + parseFloat(calculateTax(18))).toFixed(2)}
                </span>
              </p>
            </div>
            <button
              onClick={handleSubmit}
              disabled={!validateForm}
              className="bg-black-color w-full block py-2 cursor-pointer rounded-lg no-underline text-white text-center"
            >
              Check Out & Let’s Go Viral
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
