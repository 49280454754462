import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import CreatePackage from "./CreatePackage";
import CreateLive from "./CreateLive";
import { authApi } from "../../api";

const Index = () => {
  const [isPackageCreated, setIsPackageCreated] = useState(false);
  const [packageType, setPackageType] = useState("basic");
  const [offeringList, setOfferingsTypeList] = useState([]);
  const [data, setData] = useState([]);

  const handleGetData = async (name, setFieldValue, setFormData) => {
 
    if (!name) {
      return;
    }

    const {
      data: { packageList },
    } = await authApi.get("/packages/getByOfferingCategory", {
      params: { name },
    });

    if (packageList) {
      setFieldValue({
        ...packageList,
        offerings: packageList?.offerings,
        offeringCategoryId:
          packageList?.offeringCategoryId?._id ||
          packageList?.offeringCategoryId,
      });
      setFormData((prev) => {
        return { ...prev, offerings: packageList?.offerings };
      });
    }
  };

  // useEffect(() => {
  //   handleContentOffering();
  // }, []);

  // const handleContentOffering = async () => {
  //   const {
  //     data: { offeringList },
  //   } = await authApi.get(`/offerings`);

  //   const contentOfferings = offeringList.reduce((acc, offering) => {
  //     if (Array.isArray(offering.contentOfferings)) {
  //       return acc.concat(offering.contentOfferings);
  //     }
  //     return acc;
  //   }, []);

  //   const uniqueContentOfferings = contentOfferings.filter(
  //     (offering, index, self) =>
  //       offering?.typeId?.name &&
  //       index ===
  //       self.findIndex((o) => o?.typeId?.name === offering?.typeId?.name)
  //   );
  //   setOfferingsTypeList(uniqueContentOfferings);
  // };

  const fetchPackagesData = async () => {
    try {
      const response = await authApi.get(`/packages/my`, {
        // params: {
        //   status: "created",
        // },
      });
      setData(response.data.packageList);
      console.log(response.data.packageList, "Fetched packages data");
    } catch (error) {
      console.error("Error fetching packages data:", error);
    }
  };

  return (
    <div className="md:px-[32px] px-[12px] py-[24px]">
      <div className="flex items-center mb-5">
        <a href="/Dashboard" className="text-black">
          <FaArrowLeft className="me-3" />
        </a>
        <span className="font-[510] flex-grow text-lg-start text-center">
          Create Package
        </span>
      </div>

      {/* <div className="package d-lg-none">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="Create-tab"
              data-bs-toggle="pill"
              data-bs-target="#Create"
              type="button"
              role="tab"
              aria-controls="Create"
              aria-selected="true"
            >
              Create Packages
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="Live-tab"
              data-bs-toggle="pill"
              data-bs-target="#Live"
              type="button"
              role="tab"
              aria-controls="Live"
              aria-selected="false"
            >
              Live Packages
            </button>
          </li>
        </ul>
        {/* <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="Create" role="tabpanel" aria-labelledby="Create-tab" tabindex="0">
                        <CreatePackage setIsPackageCreated={setIsPackageCreated} />
                    </div>
                    <div className="tab-pane fade" id="Live" role="tabpanel" aria-labelledby="Live-tab" tabindex="0">
                        <CreateLive setIsPackageCreated={setIsPackageCreated} isPackageCreated={isPackageCreated} />
                    </div>
                </div> 
      </div> */}

      <div className="">
        <div className="row gy-4">
          <div className="col-xl-8 col-lg-7">
            <CreatePackage
              handleGetData={handleGetData}
              setIsPackageCreated={setIsPackageCreated}
              packageType={packageType}
              offeringList={offeringList}
              fetchPackages={fetchPackagesData}
              setOfferingsTypeList={setOfferingsTypeList}
            />
          </div>
          <div className="col-xl-4 col-lg-5">
            <CreateLive
              setPackageType={setPackageType}
              packageType={packageType}
              setIsPackageCreated={setIsPackageCreated}
              isPackageCreated={isPackageCreated}
              data={data}
              offeringList={offeringList}
              fetchPackagesData={fetchPackagesData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
