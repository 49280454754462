import React from "react";
import { MdOutlineFullscreenExit } from "react-icons/md";
import { ASSETBASEURL } from "../../baseUrl";
import { Link } from "react-router-dom";

const GoalOriented = ({ data, addToCart }) => {
  return (
    <>
      <div className="row gy-4 buttons goalOriented">
        {Array.isArray(data) &&
          data?.length > 0 &&
          data.map((item) => (
            <React.Fragment key={item?._id}>
              <div className="col-lg-6 col-12">
                <div className="overflow-hidden w-[100%] border-[1px] border-[#DBDBDB] rounded-[8px]">
                  <div className="md:flex">
                    <div className=" px-[18px] w-[100%] py-[21px] bg-[#FFFFFF] set-Grediant">
                      {/* <img
                        src="/assets/images/Package/Grediant.png"
                        className="gridiant-Image h-[50%]"
                        alt=""
                      /> */}
                      <p className="font-[590] md:text-[20px] leading-[24px] text-[#000000]">
                        {item?.displayName || item?.name}
                      </p>
                      <h1 className="font-[510] md:text-[38px] text-[24px] leading-[45.6px] text-[#000000]">
                        ${item?.discountedPrice}
                      </h1>
                      <p className="font-[590] md:text-[16px] text-[13px] leading-[19.2px] text-[#128735] line-through">
                        ${item?.price}
                      </p>
                      <button
                        className="z-20 font-[590] text-[16px] w-100 leading-[19.2px] text-[#FFFFFF] bg-[#0A0A0A] py-[13.5px] px-[64px] rounded-[8px] mt-[6px] mb-[15px] cursor-pointer"
                        onClick={() => addToCart(item?._id, "whole")}
                      >
                        Add to Cart
                      </button>
                      <p className="font-[510] text-[16px] text-md-start text-center leading-[19.2px] text-[#0A0A0A] underline underline-offset-4">
                        Contact Sales
                      </p>
                    </div>
                    <div className="px-[18px] w-[100%] py-[21px] bg-[#F0F4FF]">
                      <p className="font-[590] md:text-[24px] text-[20px] leading-[28.8px] text-[#000000]">
                        Includes
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.packageDescription.replace(
                            /<([a-zA-Z]+)(.*?)>/g,
                            '<$1$2 style="all: revert; margin: 0; padding: 0;">'
                          ),
                        }}
                      />
                      {/* <ul className="checklist">
                        <li className="font-[510]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Distribution to 8 news outlets
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Same-day distribution
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Editorial suggestions
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          NewsLetter Banner Ad
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Banner Ad
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Name Brand
                        </li>
                        <li className="font-[510] pt-[12px]  md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          NewsLetter Ad
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="flex justify-center py-[17px] bg-[#F9F9F9] gap-[9px] items-center">
                    <a
                      href={item?.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="no-underline"
                    >
                      <p className="font-[400] md:text-[18px] text-[16px] leading-[24px] mb-0">
                        See More Details
                      </p>
                    </a>
                    <MdOutlineFullscreenExit className="w-[24px] h-[24px]" />
                  </div>
                </div>

                {/* <div className="lg:max-w-[567px] mt-[20px] border-[1px] border-[#DBDBDB] rounded-[8px]">
                  <div className="md:flex">
                    <div className="px-[18px] w-[100%] py-[21px] bg-[#FFFFFF] set-Grediant">
                     
                      <p className="font-[590] md:text-[20px] leading-[24px leading-[24px] text-[#000000]">
                        Lite
                      </p>
                      <p className="font-[400] text-[#5E5E5E] md:text-[16px] text-[14px] leading-[24px]">
                        Description here about package type here
                      </p>
                      <h1 className="font-[510] md:text-[38px] text-[24px] leading-[45.6px] text-[#000000]">
                        $5000
                      </h1>
                      <p className="font-[590] md:text-[16px] text-[13px] leading-[19.2px] text-[#128735]">
                        15% off
                      </p>
                      <button className="font-[590] text-[16px] w-100 leading-[19.2px] text-[#FFFFFF] bg-[#0A0A0A] py-[13.5px] px-[64px] rounded-[8px] mt-[6px] mb-[15px]">
                        Add to Cart
                      </button>
                      <p className="font-[510] text-[16px] text-md-start text-center leading-[19.2px] text-[#0A0A0A] underline">
                        Contact Sales
                      </p>
                    </div>
                    <div className="px-[18px] w-[100%] py-[21px] bg-[#F0F4FF]">
                      <p className="font-[590] md:text-[24px] text-[20px] leading-[28.8px] text-[#000000]">
                        Includes
                      </p>
                      <ul className="checklist">
                        <li className="font-[510] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Distribution to 8 news outlets
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Same-day distribution
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Editorial suggestions
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          NewsLetter Banner Ad
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Banner Ad
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          Name Brand
                        </li>
                        <li className="font-[510] pt-[12px] md:text-base text-[13px] leading-[19.2px] text-[#434343]">
                          NewsLetter Ad
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex justify-center py-[17px] bg-[#F9F9F9] gap-[9px] items-center">
                    <p className="font-[400] md:text-[20px] text-[16px] leading-[24px] mb-0">
                      See More Details
                    </p>
                    <MdOutlineFullscreenExit className="w-[24px] h-[24px]" />
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6 col-12">
                {/* <video
                  className=""
                  src={ASSETBASEURL + "/" + item?.videoUrl}
                  autoPlay
                >
                  Your browser does not support the video tag.
                </video> */}
                <div className="bg-gradient-color1 rounded-[8px] p-5">
                  <h1 className="text-white mb-4">
                    Get your goal oriented package customised.
                  </h1>
                  <Link
                    to="#"
                    className="bg-white py-[12px] mb-3 me-md-4 font-[510] px-[20px] rounded-lg no-underline text-black inline-block md:w-auto w-[100%] text-center"
                  >
                    Book A Call
                  </Link>
                  <Link
                    to="#"
                    className="bg-white py-[12px] mb-3 font-[510] px-[20px] rounded-lg no-underline text-black inline-block md:w-auto w-[100%] text-center"
                  >
                    Ping on Telegram
                  </Link>
                  <p className="text-white mb-0">*Powered by Koinpr One</p>
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default GoalOriented;
