import {
  FETCH_OFFERING_DATA_SUCCESS,
  FETCH_OFFERING_DATA_FAILURE,
  FETCH_OFFERING_DATA_REQUEST,
  ADD_OFFERING_DATA_SUCCESS,
  ADD_OFFERING_DATA_FAILURE,
  ADD_OFFERING_DATA_REQUEST,
  UPDATE_OFFERING_DATA_FORM,
  REMOVE_OFFERING_DATA_FORM,
  REMOVE_ALL_OFFERING_ITEM,
} from "../actions/offering.action";

const initialState = {
  offeringData: {
    basicDetails: {},
    contentOfferings: [{}],
  },
  error: null,
  loading: false,
};

const offeringReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFERING_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_OFFERING_DATA_SUCCESS:
      return {
        ...state,
        offeringData: action.payload,
        error: null,
        loading: false,
      };
    case FETCH_OFFERING_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ADD_OFFERING_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_OFFERING_DATA_SUCCESS:
      return {
        ...state,
        offeringData: [...state.offeringData, action.payload],
        error: null,
        loading: false,
      };
    case ADD_OFFERING_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UPDATE_OFFERING_DATA_FORM:
      return {
        ...state,
        offeringData: { ...state.offeringData, ...action.payload },
      };
    case REMOVE_ALL_OFFERING_ITEM:
      return {};
    case REMOVE_OFFERING_DATA_FORM:
      return {
        ...state,
        offeringData: { ...state.offeringData },
      };
    default:
      return state;
  }
};

export default offeringReducer;
