import React from "react";
import Index from "../../Components/create-package/Index";
import { Wrapper } from "../../layout";

const CreatePackage = () => {
  return (
    <Wrapper>
      <Index />
    </Wrapper>
  );
};

export default CreatePackage;
