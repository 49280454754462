import React,{useState} from 'react'
import SignUpType from './advertise-mobile/SignUpType';
import SignUpMobile from './advertise-mobile/SignUpMobile'
import SignInMobile from './advertise-mobile/SignInMobile';
import { Link } from 'react-router-dom';

const SignUp = () => {
  const [currentComponent, setCurrentComponent] = useState('SignType');

  const handleProceed = () => {
    setCurrentComponent('Started');
  }

  const handleBack = () => {
    setCurrentComponent('SignType');
  }

  const handleSignIn = () => {
    setCurrentComponent('SignIn');
  }

  const handleSignUp = () => {
    setCurrentComponent('Started');
  }
  return (
    <div className='sign-up'>
      <div className='d-md-flex d-none  min-h-screen'>
        <div className='col-4 d-lg-block d-none'>
          <img src='/assets/images/signup/sideframe.png' className='h-full w-full object-cover object-left' alt='slide_frame' />
        </div>
        <div className='col-lg-8 d-md-block d-none'>
          <div className='p-[100px] h-full'>
            <img src='/assets/images/logo.png' alt='logo' className='d-lg-none w-[158px]' />
            <div className='flex items-center justify-center h-full'>
              <div>
                <h3 className='text-[24px] font-[590]'>Let’s Get Started</h3>
                <p>Already have an account? <a href='/advertiser-signin' className='blue-color underline-offset-4'>Sign in</a></p>
                <form>
                  <h3 className='text-[20px] mt-5 mb-3 font-[590]'>Choose Your Account Type</h3>
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-check bg-white shadows p-[14px] rounded-lg">
                        <input className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]" checked type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label font-[500]" for="flexRadioDefault1">
                          I am an advertiser
                        </label>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className="form-check bg-white shadows p-[14px] rounded-lg">
                        <input className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label font-[500]" for="flexRadioDefault1">
                          I am a publisher
                        </label>
                      </div>
                    </div>
                  </div>
                  <h3 className='text-[20px] mt-4 mb-3 font-[590]'>Account Details</h3>
                  <div className='row gy-4'>
                    <div className='col-6'>
                      <div>
                        <input type='text' placeholder='Full name' className='sign-input' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div>
                        <input type='email' placeholder='Email ID' className='sign-input' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div>
                        <input type='password' placeholder='Password' className='sign-input' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div>
                        <input type='password' placeholder='Confirm Password' className='sign-input' />
                      </div>
                    </div>
                  </div>
                  <p className='mt-5'>By proceeding you agree to Koinpr terms and conditions </p>
                  <Link to="/advertiser-signin" className='bg-black-color no-underline inline-block text-center leading-[46px] px-[20px] text-white rounded-lg w-[270px] h-[46px]'>Proceed</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-md-none'>
        <div>
          {currentComponent === 'SignType' && <SignUpType onProceed={handleProceed} signIn={handleSignIn}/>}
          {currentComponent === 'Started' && <SignUpMobile onBack={handleBack} onSignIn={handleSignIn} />}
          {currentComponent === 'SignIn' && <SignInMobile onSignUp={handleSignUp} />}
        </div>
      </div>
    </div>
  )
}

export default SignUp
