import React from 'react'
import Index from '../Components/my-cart/Index'

const MyCart = () => {
  return (
    <div>
      <Index/>
    </div>
  )
}

export default MyCart
