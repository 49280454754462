import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import Wallet from "./Wallet";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketplaceData } from "../../redux/actions/marketplace.actions";
import { useNavigate } from "react-router-dom";
import { authApi } from "../../api";
import useFetch from "../../hooks/fetchOrders";

export default function PublisherDashboard() {
  const dispatch = useDispatch();
  const [userAmount, setUserAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const navigate = useNavigate();
  const { marketplaceData, error } = useSelector((state) => state.marketplace);
  const user = JSON.parse(localStorage.getItem("user"));

  const { orders } = useFetch(user?.userType);
  console.log({ orders });

  useEffect(() => {
    const totalAmount =
      Array.isArray(orders) &&
      orders?.length > 0 &&
      orders?.reduce((acc, order) => {
        if (order?.publisherId?._id === user?._id) {
          return acc + (order?.totalPublishAmount || 0);
        }
        return acc;
      }, 0);

    setTotalValue(totalAmount);
  }, [orders, user]);

  useEffect(() => {
    dispatch(fetchMarketplaceData());
  }, [dispatch]);

  useEffect(() => {
    console.log("Marketplace Data:", marketplaceData);
    console.log("Error:", error);
  }, [marketplaceData, error]);

  const getAverageKPRScore = () => {
    let totalKPRScore = 0;
    let totalKPRScoreCount = 0;

    if (marketplaceData && marketplaceData.offeringList) {
      marketplaceData.offeringList.forEach((offering) => {
        totalKPRScore += offering?.kprMetric || 0;
        totalKPRScoreCount++;
      });
    }
    return totalKPRScoreCount > 0 ? totalKPRScore / totalKPRScoreCount : 0;
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await authApi.get(`/auth/fetch`);
        setUserAmount(response?.data?.user?.withdrawAmount);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUserData();
  }, []);
  return (
    <>
      <div className="flex dashboard">
        <div className="container-fluid !pl-[290px] !pr-[30px] py-[24px]">
          <DashboardHeader />
          <div>
            <div className="row">
              <div className="col-9">
                <div className="mt-[40px] me-4">
                  <div className="grid grid-cols-3 gap-3">
                    {/* --- first --- */}

                    <div className="flex items-center shadow-lg shadow-black justify-between bg-[#FFFFFF] border-[1px] border-[#DBDBDB] rounded-[8px] py-[14px] px-[20px]">
                      <div>
                        <h4 className="font-[510] text-[16px] leading-[19.2px] text-[#5E5E5E]">
                          Average KPR Score
                        </h4>
                        <h1 className="font-[510] mt-[20px] mb-0 text-[31px] leading-[37.2px] text-[#000000]">
                          {getAverageKPRScore().toFixed(2)}
                        </h1>
                      </div>
                      <div>
                        <img
                          src="/assets/images/Home-Page/solar_round-graph-outline.png"
                          alt=""
                        />
                      </div>
                    </div>

                    {/* --- second --- */}

                    <div className="flex items-center shadow-lg shadow-black justify-between bg-[#FFFFFF] border-[1px] border-[#DBDBDB] rounded-[8px] py-[14px] px-[20px]">
                      <div>
                        <h4 className="font-[510] text-[16px] leading-[19.2px] text-[#5E5E5E]">
                          Total Withdrawal
                        </h4>
                        <h1 className="font-[510] mt-[20px] mb-0 text-[31px] leading-[37.2px] text-[#000000]">
                          ${userAmount || 0}
                        </h1>
                      </div>
                      <div>
                        <img
                          src="/assets/images/Home-Page/solar_round-graph-outline.png"
                          alt=""
                        />
                      </div>
                    </div>

                    {/* --- third --- */}

                    <div className="flex items-center shadow-lg shadow-black justify-between bg-[#FFFFFF] border-[1px] border-[#DBDBDB] rounded-[8px] py-[14px] px-[20px]">
                      <div>
                        <h4 className="font-[510] text-[16px] leading-[19.2px] text-[#5E5E5E]">
                          Total Order Amount
                        </h4>
                        <h1 className="font-[510] mb-0 mt-[20px] text-[31px] leading-[37.2px] text-[#000000]">
                          ${totalValue || 0}
                        </h1>
                      </div>
                      <div>
                        <img
                          src="/assets/images/Home-Page/solar_round-graph-outline.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[40px]">
                  <h2 className="font-[590] text-[20px] leading-[24px] text-[#18171C]">
                    Explore Marketplace
                  </h2>
                  {/* ???????????????????????????????????????????????????????????????????? */}
                  <div className="mt-[20px]">
                    <div className="grid grid-cols-3 gap-4">
                      {marketplaceData &&
                        marketplaceData?.offeringList &&
                        marketplaceData?.offeringList.map((offering) => (
                          <div
                            key={offering?._id}
                            className="bg-[#FFF] rounded-[10px] mb-4"
                            onClick={() => {
                              navigate(
                                `/AddOffering?categoryId=${offering?.offeringCategory?._id}&category=${offering?.offeringCategory?.name}`
                              );
                            }}
                          >
                            {console.log({ offering })}
                            <div className="py-[20px] px-[20px]">
                              <div className="flex justify-between">
                                <img
                                  src={
                                    typeof offering?.basicDetails?.[
                                      "Website LOGO"
                                    ]?.[0] === "string"
                                      ? `${process.env.REACT_APP_ASSET_URL}/${offering?.basicDetails?.["Website LOGO"]?.[0]}`
                                      : "/assets/images/Home-Page/marketPlace1.png"
                                  }
                                  alt=""
                                  className="w-12 h-12 rounded-full"
                                />
                                <h4 className="flex gap-2 font-[510] text-[13px] leading-[15.6px]">
                                  {offering?.basicDetails?.[`Website Name`]}{" "}
                                  <a
                                    href={`https://${offering?.basicDetails?.websiteURL}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#3772FF]"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <FaExternalLinkAlt />
                                  </a>
                                </h4>
                              </div>
                              <div className="flex justify-between items-center">
                                <h1 className="font-semibold text-[16px] mt-[14px] text-[#000000]">
                                  {offering?.basicDetails?.["websiteName"]}
                                </h1>
                                <div className="">
                                  <span
                                    className={`${
                                      offering?.status === "approved"
                                        ? "text-green-500"
                                        : offering?.status === "created"
                                        ? "text-[grey]"
                                        : offering?.status === "rejected"
                                        ? "text-red-500"
                                        : "text-yellow-400"
                                    }  font-bold   capitalize text-[12px] leading-14.32`}
                                  >
                                    {offering?.status}
                                  </span>
                                </div>
                              </div>
                              <h2 className="font-normal text-[14px] text-[#00000080]">
                                {offering?.basicDetails?.["websiteDescription"]}
                              </h2>
                              <div className="flex flex-wrap gap-2 mt-[14px]">
                                <div className="metric-info">
                                  <FaRegEye />
                                  <span className="font-400 text-[12px] leading-14.32">
                                    {offering?.basicDetails?.websiteTraffic}
                                  </span>
                                </div>
                                <div className="metric-info">
                                  <TbWorld />
                                  <span className="font-400 text-[12px] leading-14.32">
                                    {offering?.basicDetails?.country}
                                  </span>
                                </div>
                                <div className="metric-info">
                                  <span className="font-400 text-[12px] leading-14.32">
                                    KPR {offering?.kprMetric}/10
                                  </span>
                                </div>
                                {offering?.contentOfferings &&
                                  offering?.contentOfferings.length > 0 &&
                                  offering?.contentOfferings?.map((i) => (
                                    <div className="metric-info">
                                      <span className="font-400 text-[12px] leading-14.32">
                                        {i?.typeId?.name}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>

                            <div className="border-b w-[100%] h-[1px] borde-[#DBDBDB]"></div>

                            <div className="flex justify-between items-center py-[10px] px-[20px]">
                              <div>
                                <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                                  Starting from
                                </h4>
                                <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                                  $
                                  {offering?.contentOfferings
                                    ?.map(
                                      (contentOffering) =>
                                        contentOffering?.mediaKitprice
                                    )
                                    ?.filter((price) => price != null)
                                    ?.reduce(
                                      (a, b) => Math.min(a, b),
                                      Infinity
                                    ) === Infinity
                                    ? 0
                                    : offering?.contentOfferings
                                        ?.map(
                                          (contentOffering) =>
                                            contentOffering?.mediaKitprice
                                        )
                                        ?.filter((price) => price != null)
                                        ?.reduce((a, b) => Math.min(a, b))}
                                </h3>
                              </div>
                              {/* <div className="flex gap-2">
                                <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                                <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                              </div> */}
                            </div>
                          </div>
                        ))}
                      {/* <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/marketPlace1.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 font-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="font-[510] text-[16px] mt-[14px] leading-[19.2px] text-[#000000]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/vuvietduc.com.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/dodo.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/marketPlace1.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/vuvietduc.com.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/dodo.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="mt-[20px]">
                    <div className="grid grid-cols-3 gap-3">
                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/marketPlace1.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/vuvietduc.com.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/dodo.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/marketPlace1.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/vuvietduc.com.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FFF] rounded-[10px]">
                        <div className="py-[20px] px-[20px]">
                          <div className="flex justify-between">
                            <img
                              src="/assets/images/Home-Page/dodo.png"
                              alt=""
                            />
                            <h4 className="flex gap-2 text-[510] text-[13px] leading-[15.6px]">
                              Todayqnew.com{" "}
                              <FaExternalLinkAlt className="text-[#3772FF]" />
                            </h4>
                          </div>
                          <h1 className="text-[510] text-[16px] mt-[10px] leading-[19.2px] text-[#000000] mt-[14px]">
                            Todayq News
                          </h1>
                          <div className="flex flex-wrap gap-3 mt-[14px]">
                            <div className="metric-info">
                              <FaRegEye />
                              <span className="font-400 text-[12px] leading-14.32">
                                1.5M
                              </span>
                            </div>
                            <div className="metric-info">
                              <TbWorld />
                              <span className="font-400 text-[12px] leading-14.32">
                                USA, India, Dubai
                              </span>
                            </div>

                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                KPR 7.1/10
                              </span>
                            </div>
                            <div className="metric-info">
                              <span className="font-400 text-[12px] leading-14.32">
                                Press Release
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b w-[100%] mt-[10px] mb-[5px] h-[2px] border-[2px] borde-[#DBDBDB]"></div>

                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                          <div>
                            <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                              Starting from
                            </h4>
                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                              $5000
                            </h3>
                          </div>
                          <div className="flex gap-2">
                            <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                            <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-3">
                <Wallet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
