import React, { useEffect, useState } from "react";
import SidebarWrapper from "../layout/Sidebarwrapper";
import useFetch from "../hooks/fetchOrders";
import dayjs from "dayjs";
import { cn } from "../utils/cn.util";
import { FiSearch } from "react-icons/fi";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import Table from "react-bootstrap/Table";
import OrderCount from "./OrderCount";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function MyBilling() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [expandedRowId, setExpandedRowId] = useState(null);

  const [totalValue, setTotalValue] = useState(0);
  const {
    orders,
    page,
    setOrders,
    setPage,
    completedOrders,
    totalDocuments,
    totalPages,
    query,
    setQuery,
  } = useFetch(user?.userType);

  useEffect(() => {
    setTotalValue(orders.reduce((acc, curr) => acc + curr.totalOrderAmount, 0));
  }, [orders]);
  const statusStyles = {
    created: "!text-orange-500",
    delivered: "!text-green-500",
    cancelled: "!text-red-500",
    rejected: "!text-red-500",
    refunded: "!text-blue-500",
    accepted: "!text-green-500",
    completed: "!text-green-500",
    withdrawl: "!text-blue-500",
    vendorPaid: "!text-green-500",
    requested: "!text-yellow-500",
  };

  return (
    <SidebarWrapper>
      <div className="dashboard ">
        <div className="container-fluid md:!pl-[290px] md:!pr-[30px] py-[24px]">
          <OrderCount
            completedOrders={completedOrders}
            totalDocuments={totalDocuments}
            totalValue={totalValue}
          />
          <div className="my-5 flex items-center justify-between">
            <div className="flex rounded h-[40px] w-[350px]  items-center justify-between border-[1.5px] border-[#DBDBDB] py-2 me-2">
              <input
                type="text"
                value={query}
                placeholder="Search ..."
                className="block h-full w-full pl-3 text-[15px] font-medium text-[#2B2B2B] outline-none placeholder:!text-[#2B2B2B] sm:w-fit"
                onChange={(e) => setQuery(e.target.value)}
              />
              <p className="pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
            </div>
          </div>

          {/* Table Data */}

          <div className="exampleX">
            <Table>
              <thead>
                <tr>
                  <th className="border-l !text-[#00000066]">Order Id</th>
                  <th className="border-l !text-[#00000066]">Date</th>
                  <th className="border-l !text-[#00000066]">Amount</th>
                  <th className="border-l !text-[#00000066]">Mode</th>
                  <th className="border-l !text-[#00000066]">Status</th>
                  <th className="border-l !text-[#00000066]">More Details</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(orders) &&
                  orders?.map((order) => (
                    <>
                      <tr key={order?._id} className="  hover:bg-[#F7F9FB]">
                        <td>{order?.txnId}</td>
                        <td>
                          {dayjs(order?.createdAt).format("DD,MM,YYYY - h:m A")}
                        </td>
                        <td>
                          $
                          {user?.userType === "publisher"
                            ? order?.totalPublishAmount
                            : order?.totalOrderAmount}
                        </td>
                        <td className="capitalize">{order?.mode}</td>

                        <td
                          className={cn(
                            "uppercase font-semibold",
                            statusStyles[order?.status]
                          )}
                        >
                          {order?.status}
                        </td>
                        <td
                          className="cursor-pointer"
                          onClick={() =>
                            setExpandedRowId(
                              order._id === expandedRowId ? null : order._id
                            )
                          }
                        >
                          {expandedRowId === order._id ? (
                            <IoIosArrowUp />
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </td>
                      </tr>
                      {expandedRowId === order?._id && (
                        <tr className="bg-[#D4D4D4]">
                          <td colSpan="8" className="bg-[#D4D4D4] p-4">
                            <div className="grid grid-cols-2 gap-4">
                              {order?.contentOfferings?.map((content,idx) => {
                                const matchedContent =
                                  order?.contentOffering?.[idx]?.[0]?.contentOfferings?.find(
                                    (contentOffering) =>
                                      contentOffering._id === content.id
                                  );

                                return (
                                  <div className="flex justify-between">
                                    <p>
                                      {matchedContent?.typeId?.name ??
                                        content.id}
                                    </p>
                                    <img
                                      src={`${process.env.REACT_APP_ASSET_URL}/${content?.exampleDoc}`}
                                      alt=""
                                      className="h-10 w-10"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
              </tbody>
            </Table>
          </div>

          {totalPages && (
            <div className="mb-2 flex items-center justify-end">
              <button
                disabled={page <= 1}
                onClick={() => {
                  setPage((prev) => prev - 1);
                }}
                className={`bg-slate-300 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-2xl ${
                  page <= 1 ? "!cursor-not-allowed opacity-50" : ""
                }`}
              >
                <MdOutlineNavigateBefore />
              </button>

              <div className="space-x-3 ">
                <span
                  onClick={() => setPage(1)}
                  className={`${
                    page === 1 && "bg-[#0000000D] "
                  } cursor-pointer rounded-[8px] px-[8px] py-[4px]`}
                >
                  1
                </span>
                {page > 3 && totalPages > 5 && (
                  <span className="cursor-pointer">...</span>
                )}
                {page > 2 && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page - 1)}
                    className="cursor-pointer rounded-[8px] px-[8px] py-[4px]"
                  >
                    {page - 1}
                  </span>
                )}
                {page !== 1 && page !== totalPages && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page)}
                    className="cursor-pointer rounded-[8px] bg-[#0000000D] px-[8px] py-[4px]"
                  >
                    {page}
                  </span>
                )}
                {page < totalPages - 1 && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page + 1)}
                    className="cursor-pointer rounded-[8px] px-[8px] py-[4px]"
                  >
                    {page + 1}
                  </span>
                )}
                {page < totalPages - 2 && totalPages > 5 && (
                  <span className="cursor-pointer">...</span>
                )}

                {totalPages > 1 && (
                  <span
                    onClick={() => setPage(totalPages)}
                    className={`${
                      page === totalPages && "bg-[#0000000D] "
                    } cursor-pointer rounded-[8px] px-[8px] py-[4px]`}
                  >
                    {totalPages}
                  </span>
                )}
              </div>
              <button
                disabled={page >= totalPages}
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
                className={`bg-slate-300 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-2xl ${
                  page >= totalPages ? "!cursor-not-allowed opacity-50" : ""
                }`}
              >
                <MdOutlineNavigateNext />
              </button>
            </div>
          )}
        </div>
      </div>
    </SidebarWrapper>
  );
}

export default MyBilling;
