import { useState, useEffect } from "react";


const Footer = () => {
    return (
        <>
            {/* <h1>Demo Footer</h1> */}
        </>
    )
}

export default Footer;