import React from 'react'
import { useNavigate } from "react-router-dom";

const PackageHeader = () => {
    const navigate = useNavigate()
    const redirectPage = (url) => {
        navigate(url)
    }
    return (
        <>
            <div className="flex justify-between items-center mb-[40px]">
                <h1 className="font-[510] text-[31px] leading-[37.2px] mb-0 text-[#000000]">My Packages </h1>
                <div>
                    <button className="font-[510] text-[20px] text-[#FFFFFF] bg-[#0A0A0A] py-[10px] px-[20px] gap-[10px] rounded-[5px] me-2" onClick={() => { redirectPage('/AddOffering') }}>+ Add Offering</button>
                    <button className="font-[510] text-[20px] border-1 border-[#0A0A0A] py-[10px] px-[20px] gap-[10px] rounded-[5px]" onClick={() => { redirectPage('/create-package') }}>Create Package</button>
                </div>
            </div>
        </>
    )
}

export default PackageHeader
