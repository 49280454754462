import React from "react";
import AddOffering from "./Add-Offering";

const index = () => {
  return (
    <div className="sticky-header">
      <div id="main-wrapper" className="main-wrapper">
        <AddOffering />
      </div>
    </div>
  );
};

export default index;
