import React from 'react'
import { FaArrowLeft } from "react-icons/fa6";

const SignUpMobile = ({ onBack, onSignIn }) => {
    return (
        <div className='d-md-none'>
            <div className='account-type sign-up py-[50px] flex flex-col min-h-screen'>
                <div className='container flex flex-col flex-grow'>
                    <div className='flex flex-col flex-grow justify-between'>
                        <div>
                            <FaArrowLeft className='mb-5 cursor-pointer' onClick={onBack} />
                            <h3 className='text-[20px]'>Let’s Get Started</h3>
                            <p className='text-[13px]'>Already have an account? <span className='blue-color underline-offset-4 cursor-pointer' onClick={onSignIn}>Sign in</span></p>
                            <div className='row gy-4'>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Full Name</label>
                                        <input type='text' placeholder='Full name' className='sign-input' />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Email ID</label>
                                        <input type='email' placeholder='Email ID' className='sign-input' />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Password</label>
                                        <input type='password' placeholder='Password' className='sign-input' />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Confirm Password</label>
                                        <input type='password' placeholder='Confirm Password' className='sign-input' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <p className='mt-5 text-[13px]'>By proceeding you agree to Koinpr terms and conditions </p>
                            <div className='flex justify-center'>
                                <button className='bg-black-color px-[20px] text-white rounded-lg w-full h-[46px]' onClick={onSignIn}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpMobile
