import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { FaRegFileAlt } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import AddOfferingDetails from "./AddOfferingDetails";
import AddcontentOffering from "./AddContentOfferings";
import {
  addOfferingDataForm,
  addOfferingData,
  removeAllOfferingData,
} from "../../../redux/actions/offering.action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../../../baseUrl";
import { convertStateToFormData } from "../../../utils/formDataConvertor";
import { authApi, authMultiFormApi } from "../../../api";
import toast, { ToastBar } from "react-hot-toast";

const Review = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [editContentOffering, setContentOffering] = useState(false);
  const [offeringCategoryLs, setOfferingCategoryLs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dataIndex, setDataIndex] = useState(-1);
  const { offeringData } = useSelector((state) => state.offerings);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchOfferingCategoryLs();
  }, []);
  const fetchOfferingCategoryLs = async () => {
    try {
      const response = axios.get(`${BASEURL}/offering-categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOfferingCategoryLs((await response).data.offeringCategoryList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOfferEdit = () => {
    setEditMode(true);
  };

  const handleOffingsEdit = () => {
    setContentOffering(true);
  };

  const handleNext = (updatedData) => {
    dispatch(addOfferingDataForm(offeringData));
    setEditMode(false);
    setContentOffering(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(removeAllOfferingData());
      navigate("/Dashboard");
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  return (
    <div className="identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
      <h3 className="text-[20px] font-[590] mb-4">Review</h3>

      <div>
        {!editMode ? (
          <>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-[24px] font-[510]">Offering</h3>
              {/* <span
                className="flex items-center cursor-pointer blue-color text-[#3772FF] text-[13px] font-[510]"
                onClick={handleAddOfferEdit}
              >
                {" "}
                <BiEditAlt className="text-xl " /> Edit
              </span> */}
            </div>

            <div className="border-b border-[#DBDBDB]">
              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Category
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.category}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Website Name
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.websiteName}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Website URL
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.websiteURL}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Website Description
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.websiteDescription}
              </p>
              {offeringData?.basicDetails?.logo && (
                <>
                  <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                    Company Logo
                  </span>
                  <div className="border-[1px] border-[#DADADA] w-[100px] p-[10px] mt-1 mb-2">
                    <img
                      src={
                        offeringData?.basicDetails?.logo
                          ? offeringData?.basicDetails?.logo?.preview
                          : "/assets/images/Home-Page/DodoBigimage.png"
                      }
                      className="mx-auto py-2 mb-3 w-[90px]"
                      alt="upload"
                    />
                  </div>
                </>
              )}

              <span className="text-[#5E5E5E] font-[400] leading-[24px]">
                Official Email
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.officialEmail}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Telegram ID
              </span>
              <p className="font-[510] leading-[24px]">
                @ {offeringData?.basicDetails?.telegramID}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Languages
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.contentLanguage}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Regions
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.geoLocations}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Gambling
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.gambling}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Adult Content
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.adultContent}
              </p>

              <span className="text-[#5E5E5E] font-[400] leading-[24px] ">
                Crypto/Web3.0
              </span>
              <p className="font-[510] leading-[24px]">
                {offeringData?.basicDetails?.crypto}
              </p>
            </div>
          </>
        ) : (
          <AddOfferingDetails
            setEditCategoryData={offeringData?.category}
            initialData={offeringData}
            handleNext={handleNext}
            setEditMode={editMode}
          />
        )}

        <div className="border-b border-[#DBDBDB] mt-3">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-[24px] font-[510]">Example</h3>
            {/* <span className="flex items-center cursor-pointer blue-color text-[#3772FF] text-[13px] font-[510]">
              <BiEditAlt className="text-xl " /> Edit
            </span> */}
          </div>

          <div className="mb-3">
            <span className="text-[#5E5E5E] block mb-2">Uploaded Doc</span>
            {(offeringData?.basicDetails?.files || []).map((file) => (
              <div
                key={file?.name}
                className="file-item d-flex items-center justify-between mb-2 bg-[#F1F8FF] p-3 rounded-lg"
              >
                <p className="file-name mb-0 d-flex items-center gap-3">
                  <FaRegFileAlt />
                  {file?.name}
                </p>
              </div>
            ))}
          </div>
        </div>

        {!editContentOffering ? (
          <div className="mt-3">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-[24px] font-[510]">Content Offerings</h3>
              {/* <span
                className="flex items-center cursor-pointer blue-color text-[#3772FF] text-[13px] font-[510]"
                onClick={handleOffingsEdit}
              >
                <BiEditAlt className="text-xl " /> Edit
              </span> */}
            </div>
            {offeringData?.contentOfferings?.map((item) => (
              <div className="bg-[#F8F8F8] border-[1px] border-[#C4C4C4] rounded-[8px] p-[20px] mt-[20px]">
                <div className="flex justify-between">
                  {console.log({ item })}
                  <h4 className="font-[590] text-[20px] leading-[23.87px] text-[#464646] ">
                    {item?.offeringName || item?.typeId?.name}
                  </h4>
                </div>
                <div className="flex flex-wrap gap-3 mt-[14px]">
                  <div className="metric-info gap-2 !py-[12px] !px-[19px] rounded-[4px] !bg-[#EDEDED]">
                    {/* <FaMoneyBillWave /> */}
                    <img
                      src="/assets/images/account/payments.png"
                      alt="payment"
                    />
                    <span className="font-[510] text-[12px] leading-[20.04px]">
                      Media Kit Price: {item?.mediaKitprice}
                    </span>
                  </div>
                  <div className="metric-info gap-2 !py-[12px] !px-[19px] rounded-[4px] !bg-[#EDEDED]">
                    {/* <FaMoneyBillWave /> */}
                    <img
                      src="/assets/images/account/payments.png"
                      alt="payment"
                    />
                    <span className="font-[510] text-[12px] leading-[20.04px]">
                      Discounted Price: {item?.mediaDiscountedKitPrice}
                    </span>
                  </div>
                </div>
                <div className="mt-3 bg-[#EDEDED] py-[8px] px-[19px] border-[1px] border-[#D6D6D6] rounded-[3px]">
                  <p className="font-[510] text-[12px] leading-[14.32px] text-[#000000]">
                    Features
                  </p>

                  <div className="flex flex-wrap gap-3 mt-[14px]">
                    {item?.featuresPreview?.map((items, index) => {
                      return (
                        <div
                          key={index}
                          className="metric-info gap-2 !py-[2px] !px-[10px] rounded-[4px] !bg-[#FFFFFF]"
                        >
                          <span className="font-[510] text-[14px] leading-[21px]">
                            {items?.name} : {items?.value}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <AddcontentOffering
            initialData={offeringData}
            handleNext={handleNext}
            setEditMode={editMode}
            setDataIndex={setDataIndex}
          />
        )}

        <button
          className="bg-black-color px-[20px] text-white rounded-lg w-full h-[46px] mt-10"
          onClick={handleSubmit}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default Review;
