import React, { useEffect } from "react";
import OfferingHeader from "./OfferingHeader";
import { RiEdit2Line } from "react-icons/ri";
import { FaExternalLinkAlt, FaMoneyBillWave } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketplaceData } from "../../redux/actions/marketplace.actions";

export default function PublisherDashboard() {
  const { marketplaceData, error } = useSelector((state) => state.marketplace);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarketplaceData());
  }, [dispatch]);

  return (
    <div className="dashboard">
      <div className="container-fluid !pl-[290px] !pr-[30px] py-[24px]">
        <div className="mt-[20px]">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            {marketplaceData &&
              marketplaceData?.offeringList &&
              marketplaceData?.offeringList.map((offering) => (
                <div
                  key={offering?._id}
                  className="bg-[#FFF] rounded-[10px] mb-4"
                >
                  <div className="py-[20px] px-[20px]">
                    <div className="flex justify-between">
                      <img
                        src={
                          typeof offering?.basicDetails?.["Website LOGO"]?.[0] ===
                            "string"
                            ? `${process.env.REACT_APP_ASSET_URL}/${offering?.basicDetails?.["Website LOGO"]?.[0]}`
                            : "/assets/images/Home-Page/marketPlace1.png"
                        }
                        alt=""
                        className="w-12 h-12 rounded-full"
                      // Replace with offering basic details image if available
                      />
                      <h4 className="flex gap-2 font-[510] text-[13px] leading-[15.6px]">
                        {offering?.basicDetails?.[`Website Name`]}{" "}
                        <a
                          href={`https://${offering?.basicDetails?.["Website URL"]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#3772FF]"
                        >
                          <FaExternalLinkAlt />
                        </a>
                      </h4>
                    </div>
                    <h1 className="font-semibold text-[16px] mt-[14px] text-[#000000]">
                      {offering?.basicDetails?.["websiteName"]}
                    </h1>
                    <h2 className="font-normal text-[14px] text-[#00000080]">
                      {offering?.basicDetails?.["websiteDescription"]}
                    </h2>
                    <div className="flex flex-wrap gap-3 mt-[14px]">
                      <div className="metric-info">
                        <FaRegEye />
                        <span className="font-400 text-[12px] leading-14.32">
                          {offering?.basicDetails?.websiteTraffic}
                        </span>
                      </div>
                      <div className="metric-info">
                        <TbWorld />
                        <span className="font-400 text-[12px] leading-14.32">
                          {offering?.basicDetails?.country}
                        </span>
                      </div>
                      <div className="metric-info">
                        <span className="font-400 text-[12px] leading-14.32">
                          KPR {offering?.kprMetric}/10
                        </span>
                      </div>
                      {offering?.contentOfferings &&
                        offering?.contentOfferings.length > 0 &&
                        offering?.contentOfferings?.map((i) => (
                          <div className="metric-info">
                            <span className="font-400 text-[12px] leading-14.32">
                              {i?.typeId?.name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="border-b w-[100%] h-[1px] bg-[#DBDBDB]"></div>

                  <div className="flex justify-between items-center py-[10px] px-[20px]">
                    <div>
                      <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999]">
                        Starting from
                      </h4>
                      <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px]">
                        $5000
                      </h3>
                    </div>
                    {/* <div className="flex gap-2">
                                <CiBookmark className="w-[30px] h-[30px] text-[#5E5E5E]" />
                                <IoCartOutline className="w-[30px] h-[30px] text-[#5E5E5E]" />
                              </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
