import React from 'react'
import Started from '../Components/sign-up/mobile-view/Started'

const GetStarted = () => {
  return (
    <div>
      <Started/>
    </div>
  )
}

export default GetStarted
