import React from 'react'
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";

const SignInMobile = ({ onSignUp }) => {
    return (
        <div className='d-md-none'>
            <div className='account-type sign-up py-[50px] flex flex-col min-h-screen'>
                <div className='container flex flex-col flex-grow'>
                    <div className='flex flex-col flex-grow justify-between'>
                        <div>
                            <FaArrowLeft className='mb-5 cursor-pointer' onClick={onSignUp} />
                            <h3 className='text-[24px]'>Sign In</h3>
                            <p className='mb-5'>Don’t have an account? <a href='/advertiser-signup' className='blue-color underline-offset-4'>Sign up</a></p>
                            <div className='row gy-4'>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Email ID</label>
                                        <input type='email' placeholder='Email ID' className='sign-input' />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div>
                                        <label className='text-[13px]'>Password</label>
                                        <input type='password' placeholder='Password' className='sign-input' />
                                    </div>
                                </div>
                                <a href='#' className='text-black no-underline mt-3 inline-block'>Forget Password?</a>
                            </div>
                        </div>
                        <div className='w-full'>
                            <Link to='/advertiser-verification' className='bg-black-color block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg w-full h-[46px] mt-[24px]'>Sign In</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInMobile
