import React from 'react'

const AdvertiserDetails = ({ handleNext }) => {
    return (
        <div className='identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]'>
            <h3 className='md:text-[20px] text-base font-[590]'>Identification Details</h3>
            <p className='mb-5 md:text-base text-[13px]'>Your details will be used for billing and generating invoice</p>

            <div className='mb-5'>
                <h3 className='md:text-[20px] text-base font-[590] mb-4'>I represent a company</h3>
                <div className='flex gap-5'>
                    <div className="form-check">
                        <input className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label font-[500] md:text-base text-[13px]" htmlFor="flexRadioDefault1">
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                        <label className="form-check-label font-[500] md:text-base text-[13px]" htmlFor="flexRadioDefault2">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className='sign-up'>
                <h3 className='md:text-[20px] text-base font-[590] mb-4'>Enter your details</h3>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='row gy-4'>
                            <div className='col-sm-6'>
                                <input type='text' placeholder='Full name' className='sign-input' />
                            </div>
                            <div className='col-sm-6'>
                                <input type='email' placeholder='Email ID' className='sign-input' />
                            </div>
                            <div className='col-sm-6'>
                                <input type='text' placeholder='Country of Residence' className='sign-input' />
                            </div>
                            <div className='col-sm-6'>
                                <input type='text' placeholder='Address' className='sign-input' />
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className='bg-black-color px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]'
                    onClick={handleNext}
                >
                    Next
                </button>
                <p className='mb-0 col-lg-8 mt-2 md:text-base text-[13px]'>Please make sure that the details you enter here matches the documents you will be providing for verification.</p>
            </div>
        </div>
    )
}

export default AdvertiserDetails
