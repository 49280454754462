import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import AddContentOffering from "./AddContentOfferings";
import { FaCheck } from "react-icons/fa";
import Review from "./Review";
import Drafts from "./drafts";
import Introduction from "./introduction";
import AddOfferingDetails from "./AddOfferingDetails";
import { useLocation } from "react-router-dom";
import { authMultiFormApi } from "../../../api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addOfferingDataForm } from "../../../redux/actions/offering.action";

export default function AddOffering() {
  const [selectedStep, setSelectedStep] = useState("Add Offering");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [dataIndex, setDataIndex] = useState(-1);
  const steps = ["Add Offering", "Add Content Offerings", "Review"];
  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const categoryId = queryParams.get("categoryId");
  const category = queryParams.get("category");

  const handleNext = async (offeringData) => {
    try {
      if (!offeringData?._id) {
        // Create a new offering
        const response = await authMultiFormApi.post(
          "/offerings",
          offeringData,
          {
            params: { status: "created" },
          }
        );

        if(selectedStep === "Add Offering") {
          dispatch(addOfferingDataForm({_id : response.data.offering._id}));
        }
      } else {
        // Update an existing offering
        await authMultiFormApi.patch(
          `/offerings/${offeringData._id}`,
          offeringData,
          {
            params: { status: "created" },
          }
        );
      }

      toast.success(
        `Offering ${offeringData?._id ? "Edited" : "Added"} successfully`
      );
      const currentIndex = steps.indexOf(selectedStep);
      if (currentIndex < steps.length - 1) {
        const nextStep = steps[currentIndex + 1];
        setSelectedStep(nextStep);
        if (!completedSteps.includes(selectedStep)) {
          setCompletedSteps([...completedSteps, selectedStep]);
        }
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const handleBack = () => {
    const currentIndex = steps.indexOf(selectedStep);
    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex - 1];
      setSelectedStep(nextStep);
      if (!completedSteps.includes(selectedStep)) {
        setCompletedSteps([...completedSteps, selectedStep]);
      }
    }
  };

  return (
    <>
      <div className="Add-Offering">
        <div className="md:px-[32px] px-[12px] py-[24px]">
          <div className="flex items-center mb-5">
            <a
              href="/Dashboard"
              className="!text-[510] font-[20px] leading-[24px] text-[#18171C]"
            >
              <FaArrowLeft className="me-3" />
            </a>
            <span className="font-[510] md:text-[20px] flex-grow text-md-start text-center">
              Add Offering
            </span>
          </div>
          <div className="row gy-4">
            <div className="col-xl-2 col-lg-3 col-12">
              <div className="bg-white border-1 border-[#DBDBDB] rounded-lg p-3">
                <h3 className="text-[20px] font-[590] leading-[24px]">
                  Progress
                </h3>
                <p className="text-sm border-b border-[#DBDBDB] pb-2"></p>

                <div className="d-lg-block d-flex gap-2 justify-between">
                  {steps &&
                    steps?.map((step, index) => {
                      const isCompleted = completedSteps.includes(step);
                      const isSelected = selectedStep === step;
                      return (
                        <div
                          key={index}
                          className="md:flex text-center items-center gap-1 mb-3"
                        >
                          <div
                            className={`w-[24px] check_btn rounded-full h-[24px] border-1 flex items-center justify-center ${
                              isCompleted ? "bg-green-500" : "border-gray-500"
                            } ${isSelected ? "border-green-500" : ""}`}
                            style={{ borderColor: isSelected ? "green" : "" }}
                          >
                            {isCompleted && (
                              <FaCheck className="text-white text-sm" />
                            )}
                          </div>
                          <span className="md:text-[16px] text-[14px]">
                            {step}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-12">
              {selectedStep === "Add Offering" && (
                <AddOfferingDetails
                  handleNext={handleNext}
                  categoryId={categoryId || ""}
                  category={category || ""}
                />
              )}
              {selectedStep === "Add Content Offerings" && (
                <AddContentOffering
                  handleBack={handleBack}
                  handleNext={handleNext}
                  dataIndex={dataIndex}
                  setDataIndex={setDataIndex}
                />
              )}
              {selectedStep === "Review" && <Review />}
            </div>
            <div className="col-xl-3 col-lg-3 d-lg-block d-none">
              <div className="bg-white border-1 border-[#DBDBDB] rounded-lg p-3">
                <Drafts
                  selectedStep={selectedStep}
                  setDataIndex={setDataIndex}
                  setSelectedStep={setSelectedStep}
                />
              </div>
              <div className="bg-white border-1 border-[#DBDBDB] rounded-lg p-3 mt-3">
                <Introduction />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
