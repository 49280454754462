import React from 'react';
import PublisherDashboard from './PublishersDashboard';

const index = () => {
    return (
        <>
            <div className='sticky-header'>
                <div id="main-wrapper" className="main-wrapper">
                    <PublisherDashboard />
                </div>
            </div>
        </>
    )
}

export default index;