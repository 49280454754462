import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
  // const isAuthenticated = true;
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};

export default Protected;
