import React, { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa"; 
import AdvertiserDetails from './AdvertiserDetails';
import AdvertiserUpload from './AdvertiserUpload';
import AdvertiserReview from './AdvertiserReview';

const AdvertiserVerify = () => {
    const [selectedStep, setSelectedStep] = useState('Identification Details');
    const [completedSteps, setCompletedSteps] = useState([]);
    const steps = ['Identification Details', 'Upload Documents', 'Review'];

    const handleNext = () => {
        const currentIndex = steps.indexOf(selectedStep);
        if (currentIndex < steps.length - 1) {
            const nextStep = steps[currentIndex + 1];
            setSelectedStep(nextStep);
            if (!completedSteps.includes(selectedStep)) {
                setCompletedSteps([...completedSteps, selectedStep]);
            }
        }
    };

    return (
        <div className='account-verify'>
            <div className='md:px-[32px] px-[12px] py-[24px]'>
                <div className='flex items-center mb-5'>
                    <a href='/' className='text-black'><FaArrowLeft className='me-3' /></a>
                    <span className='d-lg-block d-none'>Account Verification</span>
                    <span className='d-lg-none font-[590] flex-grow text-center'>Account Details</span>
                </div>
                <div className='row gy-4'>
                    <div className='col-lg-3'>
                        <div className='bg-white border-1 border-[#DBDBDB] rounded-lg p-3'>
                            <h3 className='text-[20px] font-[590] d-lg-block d-none'>Account Details</h3>
                            <p className='text-sm border-b border-[#DBDBDB] pb-2'>Current Status : <span className='text-[#D71212]'>Not Verified</span></p>

                            <form className='d-lg-block d-flex gap-2 justify-between'>
                                {steps.map((step, index) => {
                                    const isCompleted = completedSteps.includes(step);
                                    const isSelected = selectedStep === step;
                                    return (
                                        <div key={index} className='md:flex text-center items-center gap-1 mb-3'>
                                            <div
                                                className={`w-[24px] check_btn rounded-full h-[24px] border-1 flex items-center justify-center ${isCompleted ? 'bg-green-500' : 'border-gray-500'} ${isSelected ? 'border-green-500' : ''}`}
                                                style={{ borderColor: isSelected ? 'green' : '' }}
                                            >
                                                {isCompleted && <FaCheck className='text-white text-sm' />}
                                            </div>
                                            <span className='md:text-[16px] text-[14px]'>{step}</span>
                                        </div>
                                    );
                                })}
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        {selectedStep === 'Identification Details' && <AdvertiserDetails handleNext={handleNext} />}
                        {selectedStep === 'Upload Documents' && <AdvertiserUpload handleNext={handleNext} />}
                        {selectedStep === 'Review' && <AdvertiserReview />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvertiserVerify
