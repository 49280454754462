import { createStore, applyMiddleware } from "redux";

import { thunk } from "redux-thunk";
import reducers from "../reducers/Index.reducer";
import { composeWithDevTools } from "@redux-devtools/extension";

const middlewares = [thunk];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
