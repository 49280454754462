import React from 'react';
import PublisherDashboard from './MarketplaceDashboard';
import PackageFooter from '../package/PackageFooter';

const index = () => {
    return (
        <>
            <div className='sticky-header'>
                <div id="main-wrapper" className="main-wrapper">
                    <PublisherDashboard />
                </div>
            </div>
            <PackageFooter />
        </>
    )
}

export default index;