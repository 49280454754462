import React from "react";
import SidebarWrapper from "../layout/Sidebarwrapper";
import { FiSearch } from "react-icons/fi";
import useFetch from "../hooks/fetchTransations";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import Table from "react-bootstrap/Table";
import dayjs from "dayjs";
import { cn } from "../utils/cn.util";

function PaymentHistory() {
  const {
    transactions,
    page,
    setTransactions,
    setPage,
    totalPages,
    query,
    setQuery,
  } = useFetch();

  const statusStyles = {
    initiated: "!text-orange-500",
    success: "!text-green-500",
    failed: "!text-red-500",
    refunded: "!text-blue-500",
    partially_refunded: "!text-blue-500",
  };

  return (
    <SidebarWrapper>
      <div className="dashboard ">
        <div className="container-fluid md:!pl-[290px] md:!pr-[30px] py-[24px]">
          <div className="my-5 flex items-center justify-between">
            <div className="flex rounded h-[40px] w-[350px]  items-center justify-between border-[1.5px] border-[#DBDBDB] py-2 me-2">
              <input
                type="text"
                value={query}
                placeholder="Search ..."
                className="block h-full w-full pl-3 text-[15px] font-medium text-[#2B2B2B] outline-none placeholder:!text-[#2B2B2B] sm:w-fit"
                onChange={(e) => setQuery(e.target.value)}
              />
              <p className="pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
            </div>
          </div>

          {/* Table Data */}

          <div className="exampleX">
            <Table>
              <thead>
                <tr>
                  <th className="border-l !text-[#00000066]">Transation Id</th>
                  <th className="border-l !text-[#00000066]">Date</th>
                  <th className="border-l !text-[#00000066]">Amount</th>
                  <th className="border-l !text-[#00000066]">Mode</th>
                  <th className="border-l !text-[#00000066]">Status</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(transactions) &&
                  transactions?.map((user) => (
                    <tr key={user?._id} className="  hover:bg-[#F7F9FB]">
                      <td className="!text-blue-500">
                        {user?.txnId?.split("_")[2] || user?.txnId}
                      </td>
                      <td>
                        {dayjs(user?.createdAt).format("DD, MMM, YYYY - h:m A")}
                      </td>
                      <td>${user?.amount}</td>
                      <td className="capitalize">{user?.paymentMethod}</td>

                      <td
                        className={cn(
                          "uppercase font-semibold",
                          statusStyles[user?.status]
                        )}
                      >
                        {user?.status}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {totalPages && (
            <div className="mb-2 flex items-center justify-end">
              <button
                disabled={page <= 1}
                onClick={() => {
                  setPage((prev) => prev - 1);
                }}
                className={`bg-slate-300 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-2xl ${
                  page <= 1 ? "!cursor-not-allowed opacity-50" : ""
                }`}
              >
                <MdOutlineNavigateBefore />
              </button>

              <div className="space-x-3 ">
                <span
                  onClick={() => setPage(1)}
                  className={`${
                    page === 1 && "bg-[#0000000D] "
                  } cursor-pointer rounded-[8px] px-[8px] py-[4px]`}
                >
                  1
                </span>
                {page > 3 && totalPages > 5 && (
                  <span className="cursor-pointer">...</span>
                )}
                {page > 2 && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page - 1)}
                    className="cursor-pointer rounded-[8px] px-[8px] py-[4px]"
                  >
                    {page - 1}
                  </span>
                )}
                {page !== 1 && page !== totalPages && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page)}
                    className="cursor-pointer rounded-[8px] bg-[#0000000D] px-[8px] py-[4px]"
                  >
                    {page}
                  </span>
                )}
                {page < totalPages - 1 && totalPages > 2 && (
                  <span
                    onClick={() => setPage(page + 1)}
                    className="cursor-pointer rounded-[8px] px-[8px] py-[4px]"
                  >
                    {page + 1}
                  </span>
                )}
                {page < totalPages - 2 && totalPages > 5 && (
                  <span className="cursor-pointer">...</span>
                )}

                {totalPages > 1 && (
                  <span
                    onClick={() => setPage(totalPages)}
                    className={`${
                      page === totalPages && "bg-[#0000000D] "
                    } cursor-pointer rounded-[8px] px-[8px] py-[4px]`}
                  >
                    {totalPages}
                  </span>
                )}
              </div>
              <button
                disabled={page >= totalPages}
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
                className={`bg-slate-300 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-2xl ${
                  page >= totalPages ? "!cursor-not-allowed opacity-50" : ""
                }`}
              >
                <MdOutlineNavigateNext />
              </button>
            </div>
          )}
        </div>
      </div>
    </SidebarWrapper>
  );
}

export default PaymentHistory;
