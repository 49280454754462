import React from 'react'
import { Link } from 'react-router-dom'

const SignIn = () => {
  return (
    <div className='sign-up'>
      <div className='d-md-flex d-none justify-center min-h-screen'>
        <div className='col-4 d-lg-block d-none'>
          <img src='/assets/images/signup/sideframe.png' className='h-full w-full object-cover object-left' alt='slide_frame' />
        </div>
        <div className='col-lg-8 d-md-block d-none'>
          <div className='p-[150px] h-full'>
            <div className='flex items-center justify-center h-full'>
              <div>
                <h3 className='text-[24px]'>Sign In</h3>
                <p className='mb-5'>Don’t have an account? <a href='/advertiser-signup' className='blue-color underline-offset-4'>Sign up</a></p>
                <form>
                  <div className='row gy-4'>
                    <div className='col-12'>
                      <div>
                        <input type='email' placeholder='Email ID' className='sign-input' />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div>
                        <input type='password' placeholder='Password' className='sign-input' />
                      </div>
                    </div>
                  </div>
                  <Link to='/advertiser-verification' className='bg-black-color block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg w-full h-[46px] mt-[24px]'>Sign In</Link>
                  <a href='#' className='text-black no-underline mt-3 inline-block'>Forget Password?</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
