import React, { useState, useRef } from 'react';

const AdvertiserUpload = ({handleNext}) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose document type");

  const inputRef = useRef(null);
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <div className='identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]'>
        <h3 className='md:text-[20px] text-base font-[590]'>Upload Documents</h3>
        <p className='mb-5 col-lg-7 md:text-base text-[13px]'>Please upload the required documents below in order to validate your Identification Details.</p>

        <div className='mb-5 col-lg-6'>
          <div className="dropdown p-[14px] rounded-lg bg-white shadows">
            <div onClick={() => setIsActive(!isActive)} className="dropdown-btn flex items-center justify-between">
              <span className='font-[510] md:text-base text-[13px]'>{selected}</span>
              <span className={isActive ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
            </div>
            {/* <div className="dropdown-menu mt-2 w-full" style={{ display: isActive ? "block" : "none" }}>
                          <div onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }} className="dropdown-item"> Most Relevant </div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Recently Listed</div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Newest</div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Price: Low to High</div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Price: High to Low</div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Most Likes</div>
                          <div className="dropdown-item" onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); }}>Most Views</div>
                      </div> */}
          </div>
        </div>

        <div className='mt-4 col-lg-6'>
          <h3 className='md:text-[20px] text-base font-[590] mb-3'>Upload File</h3>
          <div className="image_upload">
            <form id="form-file-upload">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <input ref={inputRef} type="file" id="input-file-upload" multiple />
                <div className='font-[300]'>
                  <img src='/assets/images/account/upload.png' className='mx-auto mb-3 w-[90px]' alt="upload" />
                  <p className='md:text-base text-[13px] font-[590] mb-1'>Browse Files to upload</p>
                  <div className='text-[12px]'>
                    Max file size 5 MB. Supported file types: png, jpeg, pdf, doc.
                  </div>
                </div>
              </label>
            </form>
          </div>
        </div>

        <button
          className='bg-black-color px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]'
          onClick={handleNext}
        >
          Next
        </button>
        <p className='mb-0 col-lg-8 mt-2 md:text-base text-[13px]'>Please make sure that the details you enter here matches the documents you will be providing for verification.</p>

      </div>
    </div>
  )
}

export default AdvertiserUpload
