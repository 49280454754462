import React, { useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { IoCartOutline } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { Col, Offcanvas } from "react-bootstrap";
import { GrPowerReset } from "react-icons/gr";
import useGeo from "../../hooks/useGeo";

export default function MarketplaceHeader({
  onSortToggle,
  isAdvertiser,
  count,
  search,
  setSearch,
  noData,
  seeAllfilters,
  setSelectedLanguage,
  categories,
  selectedCategories,
  onCategorySelect,
  setFilterDropDown,
  filterDropDown,
  handleResetSubCategory,
  setFilteredOfferings,
  setExpandedCategories,
  setSelectedCategories
}) {
  console.log({ isAdvertiser });
  const [isSortedByKPR, setIsSortedByKPR] = useState(false);
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  
  const [activeSection, setActiveSection] = useState("Category");
  const { lang } = useGeo();
  const [searchTerm, setSearchTerm] = useState("");
  const cartData = useSelector((state) => state.cart.cartItems);

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubFilter, setActiveSubFilter] = useState(null);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setActiveSubFilter(null);
  };

  const handleSubFilterClick = (subFilter) => {
    setActiveSubFilter(subFilter);
  };

  const budget = [
    { id: 0, name: "$0-$100" },

    { id: 1, name: "$100-$500" },

    { id: 2, name: "$500-$1000" },

    { id: 3, name: "$1000-$5000" },
    { id: 4, name: "$5000+" },
  ];
  const filteredLanguages = lang?.filter((language) =>
    language.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const countCart = useMemo(() => {
    if (!cartData) return 0;

    const allItems = [
      ...(cartData.adminPackages || []),
      ...(cartData.offerings || []),
      ...(cartData.addOn || []),
      ...(cartData.packages || []),
    ];

    return allItems.reduce((acc, item) => {
      if (item._id || item.id) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [cartData]);

  const handleSortToggle = (e) => {
    const checked = e.target.checked;
    setIsSortedByKPR(checked);
    onSortToggle(checked);
  };

  const handleResetAll = () => {
    setSelectedLanguage([]);
    setSelectedCategories([])
    setActiveCategory(null);
    setActiveSubFilter(null);
    setSearchTerm("");
    setFilteredOfferings([])
    setExpandedCategories([])
    setSearch("");
    setActiveSection("Category");
  };
  

  const verticalSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    vertical: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    draggable: false,
    verticalSwiping: true,
  };

  return (
    <>
      <div className="sticky top-[100px]">
        <div className="d-lg-flex justify-between">
          <div className="d-flex items-center justify-between mb-lg-0 mb-3">
            <h1 className="font-[590] mb-0 lg:text-[31px] text-[24px] leading-[37.2px] d-md-block d-none">
              Koinpr Marketplace
            </h1>
            <h1 className="font-[590] mb-0 text-[20px] leading-[37.2px] d-md-none">
              Marketplace
            </h1>
            {isAdvertiser && (
              <Link to="/my-cart" className="relative d-lg-none">
                <IoCartOutline className="text-[32px] text-[#5E5E5E]" />
                <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                  {countCart}
                </span>
              </Link>
            )}
          </div>
          <div className="d-lg-none mb-4">
            <div className="row gx-0">
              <div className="col-6">
                <div class="dropdown">
                  <button
                    class="dropdown-toggle price_btn w-full bg-white blue-gradient border-rights text-center py-3 sm:text-base text-[12px] flex items-center justify-center gap-3"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <MdFilterList />
                    Sort By
                  </button>
                  <ul class="dropdown-menu w-full">
                    <li>
                      <a class="dropdown-item" href="#">
                        Price Hight to Low
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Price Low to High
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6">
                <div
                  onClick={toggle}
                  className="bg-white blue-gradient text-center py-3 sm:text-base text-[12px] flex items-center justify-center gap-3"
                >
                  <LuSettings2 />
                  Filter
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="include mt-[20px] py-[16px] px-[20px] d-block d-md-none">
          <div>
            <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF] flex gap-3">
              <img
                src="/assets/images/MarketPlace/list.png"
                className="bg-[#6E6E6E] self-start rounded-lg w-[26px]"
                alt="list"
              />
              Achieve More With Packages
            </h1>
          </div>
          <div className="listing">
            <Slider {...verticalSettings} className="text-sm">
              <div>
                <li>TikTok Influencer Packages</li>
              </div>
              <div>
                <li>ICO Conversion Packages</li>
              </div>
              <div>
                <li>Press Release Packages</li>
              </div>
              <div>
                <li>Instagram Influencer Packages</li>
              </div>
              <div>
                <li>Telegram Influencer Packages</li>
              </div>
              <div>
                <li>Youtube Influencer Packages</li>
              </div>
              <div>
                <li>Twitter Influencer Packages</li>
              </div>
            </Slider>
          </div>
          <div>
            <button className="font-[510] text-[13px] leading-[15.51px] text-[#1D1D1D] bg-[#FFFFFF] px-[8px] py-[6px] rounded-[4px] w-[100%] mt-[12px]">
              Open Packages
            </button>
          </div>
        </div> */}

        <div className="d-lg-flex d-none items-end justify-between mt-[21px]">
          <div>
            <h4 className="font-[600] text-[16px] leading-[19.36px] mb-1">
              Showing Result:{" "}
              <span className="font-[600] text-[16px] leading-[19.36px] text-[#3772FF]">
                {noData ? "0" : count}
              </span>
            </h4>
            <p className="mb-0 text-[#A4A4A4] text-sm">From All Categories</p>
          </div>
          <div className="flex buttons items-center gap-2">
            {/* <div className="relative me-3">
              <BsCart3 className="w-[24px] h-[24px]" />
              <span className="bg-black absolute -top-3 -right-3 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">0</span>
            </div> */}
            <div className="suggest_btn">
              <button className="font-[590] text-[16px] leading-[19.2px] text-[#000000] flex items-center gap-2 px-[20px] py-[15px]">
                <img src="/assets/images/MarketPlace/group.png" alt="" /> Hire
                Conversions Expert
              </button>
            </div>
            <div className="flex items-center gap-2">
              <div className="search-box !w-[200px]">
                <input
                  className="search-input outline-none"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button className="search-btn">
                  <i className="fas fa-search"></i>
                </button>
              </div>
              {/* <IoBookmarkOutline className="text-[32px] text-[#5E5E5E] d-lg-block d-none" /> */}
              {isAdvertiser && (
                <Link to="/my-cart" className="relative d-lg-block d-none">
                  <IoCartOutline className="text-[32px] text-[#5E5E5E] d-lg-block d-none" />
                  <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                    {countCart}
                  </span>
                </Link>
              )}
            </div>
            {/* <button className="font-[590] text-[16px] bg-white border border-[#DBDBDB] rounded-lg leading-[19.2px] text-[#000000] flex items-center gap-8 px-[20px] py-[15px]">
              KPR Metric
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={isSortedByKPR}
                  onChange={handleSortToggle}
                />
              </Form>
            </button> */}
          </div>
        </div>
      </div>

      {/* filter modal */}
      <Offcanvas
        show={show}
        onHide={toggle}
        placement={"bottom"}
        style={{ height: "70vh", borderRadius: "20px 20px 0 0" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex w-full items-center justify-between">
            Filters{" "}
            <span
              onClick={toggle}
              className="text-[13px] text-[#5E5E5E] cursor-pointer"
            >
              Close
            </span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="d-flex h-full">
            {/* Left Sidebar */}
            <div className="bg-[#F8F8F8] border-[1px] h-full d-flex flex-col justify-between border-[#DBDBDB] w-[40%] sticky top-0">
              <div>
                <div
                  className={`p-3 cursor-pointer text-sm ${
                    activeSection === "Category" ? "bg-white" : ""
                  }`}
                  onClick={() => setActiveSection("Category")}
                >
                  Category
                </div>
                <div
                  className={`p-3 cursor-pointer text-sm ${
                    activeSection === "Sub Filters" ? "bg-white" : ""
                  }`}
                  onClick={() => setActiveSection("Sub Filters")}
                >
                  Sub Filters
                </div>
                <div
                  className={`p-3 cursor-pointer text-sm ${
                    activeSection === "Language" ? "bg-white" : ""
                  }`}
                  onClick={() => setActiveSection("Language")}
                >
                  Language
                </div>
                <div
                  className={`p-3 cursor-pointer text-sm ${
                    activeSection === "Budget" ? "bg-white" : ""
                  }`}
                  onClick={() => setActiveSection("Budget")}
                >
                  Budget
                </div>
              </div>
              <div>
                <div className="d-flex items-center gap-2 p-3 text-[12px]"
                onClick={handleResetAll}
                >
                  Reset Filters <GrPowerReset />
                </div>
                <div className="px-3 pb-3">
                  <button className="font-[590] text-[13px] bg-white border border-[#DBDBDB] rounded-lg leading-[19.2px] text-[#000000] flex items-center gap-2 px-[5px] py-2">
                    KPR Metric
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isSortedByKPR}
                        onChange={handleSortToggle}
                      />
                    </Form>
                  </button>
                </div>
              </div>
            </div>

            {/* Right Content Section */}
            <div className="w-[60%] d-flex flex-col justify-between">
              <div>
                {activeSection === "Category" && (
                  <div className="p-3">
                    {categories?.map((category) => (
                      <div
                        key={category?._id}
                        className={`pb-3 d-flex items-center gap-2 cursor-pointer text-sm`}
                        onClick={() => {
                          onCategorySelect(category?.name);
                          handleCategoryClick(category?.name);
                        }}
                      >
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="w-[15px] h-[15px]"
                          checked={selectedCategories.includes(category?.name)}
                        />
                        {category?.name}{" "}
                        {/* Here you display the category name */}
                      </div>
                    ))}
                  </div>
                )}

                {activeSection === "Sub Filters" && (
                  <div className="p-3">
                    {activeCategory ? (
                      <>
                        <h4 className="font-bold mb-3">{activeCategory}</h4>
                        <div>
                          {seeAllfilters
                            .find((category) => category.id === activeCategory)
                            ?.options.map((subFilter, index) => {
                              const subFilterName = Object.keys(subFilter)[0];
                              
                              return (
                               <div key={index} className="mb-4">
                               <h6 className="mb-2 text-sm">{subFilterName}</h6>
                                <select
                                  className="form-select shadow-none"
                                  value={filterDropDown[subFilterName] || ""}
                                  onChange={(e) => {
                                    setFilterDropDown((prev) => ({
                                      ...prev,
                                      [subFilterName]: e.target.value,
                                    }));
                                  }}
                                >
                                  {subFilter[subFilterName].map(
                                    (option, idx) => (
                                      <option key={idx} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </select>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <p className="text-gray-500">
                        Select a category to view filters
                      </p>
                    )}
                  </div>
                )}

                {activeSection === "Language" && (
                  <div className="p-3">
                    <Form className="h-[332px] overflow-auto">
                      <Form.Group className="mb-3  mx-1 xl:mx-2">
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="mb-3"
                        />
                      </Form.Group>
                      {Array.isArray(filteredLanguages) &&
                        filteredLanguages.map((language, index) => (
                          <Form.Group
                            key={language._id || index}
                            className="mb-3"
                            id="formGridCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              label={language.name}
                              className="font-[510] text-[13px] leading-[15.6px]"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedLanguage((prev) => [
                                    ...prev,
                                    language.name,
                                  ]);
                                } else {
                                  setSelectedLanguage((prev) =>
                                    prev.filter(
                                      (lang) => lang !== language.name
                                    )
                                  );
                                }
                              }}
                            />
                          </Form.Group>
                        ))}
                    </Form>
                  </div>
                )}
                {activeSection === "Budget" && (
                  <div className="p-3">
                    <Col key={budget.id} sm={12} className="pb-1">
                      <Form.Check
                        type="radio"
                        label="None"
                        name="price"
                        id="first"
                        className="text-[13px]  leading-[15.6px]"
                        // checked={selectedPrice === budget?.name}
                        // onChange={() => {
                        //   setSelectedPrice(null);
                        // }}
                      />
                    </Col>
                    {budget?.map((budget) => (
                      <Col key={budget.id} sm={12} className="pb-1">
                        <Form.Check
                          type="radio"
                          label={budget?.name}
                          name="price"
                          id="first"
                          className="text-[14px] leading-[15.6px]"
                          // checked={selectedPrice === budget?.name}
                          // onChange={() => {
                          //   setSelectedPrice(budget?.name);
                          // }}
                        />
                      </Col>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="text-[13px] cursor-pointer d-flex items-center justify-between px-3 pb-3"
                
              >

               <span onClick={handleResetSubCategory}>Reset All</span> 
                <button
                  className="bg-black text-white px-3 py-2 rounded"
                  onClick={toggle}
                >
                  See {count} Results
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
