import { useEffect, useState } from "react";
import { authApi } from "../api";

const useCarts = () => {
  const [carts, setCarts] = useState(null);

  const fetchCart = async () => {
    try {
      const response = await authApi.get(`/carts`);
      setCarts(response?.data?.cart);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  return { carts, setCarts };
};

export default useCarts;
