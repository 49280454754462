import axios from "axios";
import { BASEURL } from "../../baseUrl";
import { toast } from "react-hot-toast";
import { authApi, authMultiFormApi } from "../../api";
import { useEffect } from "react";

export const REGISTER = "REGISTER";

export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const OTP_VERIFY_REQUEST = "OTP_VERIFY_REQUEST";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAILURE = "OTP_VERIFY_FAILURE";

export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const SUBMIT_FORM_REQUEST = "SUBMIT_FORM_REQUEST";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_FAILURE = "SUBMIT_FORM_FAILURE";

const registorUser = (payload) => ({ type: REGISTER, payload: payload });

export const registor = (access) => {
  return async (dispatch) => {
    try {
      await axios
        .post(`${BASEURL}/admin/access`, access)
        .then((res) => {
          toast.success("Access Add Successfully");
          dispatch(registorUser(res));
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          dispatch(registorUser(error?.response));
        });
    } catch (error) {
      toast.error(error?.response);
      console.log("Error login user:", error);
    }
  };
};

export const register = (userData) => {
  return async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST });

    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      const response = await axios.post(`${BASEURL}/auth/register`, userData);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: response.data, email: userData.email },
      });

      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      toast.success(
        "Registration successful! Please check your email for the verification code."
      );
    } catch (error) {
      dispatch({
        type: REGISTER_FAILURE,
        error: error.response?.data?.message || "Registration failed",
      });
      toast.error(error.response?.data?.message || "Registration failed");
    }
  };
};

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    try {
      const response = await axios.post(`${BASEURL}/auth/login`, credentials);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: response.data.data.user,
          email: credentials.email,
          password: credentials.password,
          userType: response.data.data.user.userType,
        },
      });
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");

      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      toast.success("Login successfull!");
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        error: error.response?.data?.message || "Login failed",
      });
      toast.error(error.response?.data?.message || "Login failed");
    }
  };
};

export const verifyOTP = (otp, email) => async (dispatch, getState) => {
  dispatch({ type: OTP_VERIFY_REQUEST });

  try {
    const response = await axios.post(`${BASEURL}/auth/verify-otp`, {
      email,
      otp,
    });
    dispatch({ type: OTP_VERIFY_SUCCESS, payload: response.data });
    toast.success("Email verified successfully!");
  } catch (error) {
    dispatch({
      type: OTP_VERIFY_FAILURE,
      error: error.response?.data?.message || "OTP verification failed",
    });
    toast.error(error.response?.data?.message || "OTP verification failed");
  }
};

export const updateFormData = (formData) => ({
  type: UPDATE_FORM_DATA,
  payload: formData,
});

export const resetFormData = () => ({
  type: RESET_FORM_DATA,
});

export const submitForm = (formData) => async (dispatch) => {
  dispatch({ type: SUBMIT_FORM_REQUEST });
  try {
    console.log({formData});
    
    const response = await authMultiFormApi.patch(`/auth/update`, formData, {
    });
    dispatch({ type: SUBMIT_FORM_SUCCESS, payload: response.data });

    toast.success(response.data.message);
  } catch (error) {
    dispatch({
      type: SUBMIT_FORM_FAILURE,
      error: error.response?.data?.message || "Update failed",
    });
    toast.error(error.response?.data?.message || "Update failed");
  }
};

export const logout = () => (dispatch) => {
  return new Promise((resolve) => {
    console.log("Logging out...");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    dispatch({ type: LOGOUT });
    resolve();
    console.log("Logout successfull!");
    toast.success("Logout successful!!");
  });
};

export const fetchUserData = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_DATA_REQUEST });

  try {
    const response = await authApi.get("/auth/fetch");
    dispatch({
      type: FETCH_USER_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_USER_DATA_FAILURE,
      error: error.message || "Something went wrong",
    });
  }
};
