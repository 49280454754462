import { authApi } from "../api/index";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

export default function useFetch() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [query, setQuery] = useState("");
  const debounceQuery = useDebounce(query, 300);

  const fetchTransations = useCallback(async () => {
    try {
      const response = await authApi.get(`/transactions/fetchAll`, {
        params: {
          page,
          query: debounceQuery?.length > 2 ? debounceQuery : "",
        },
      });

      if (response?.data?.totalPages) {
        setTotalPages(response?.data?.totalPages);
      }
      setTransactions(response?.data?.transactions);
    } catch (err) {
      console.log(err);
    }
  }, [page, debounceQuery]);

  // useEffect(() => {
  //   setPage(1);
  //   setTotalPages(1);
  //   setQuery("");
  // }, []);

  useEffect(() => {
    fetchTransations();
  }, [fetchTransations]);

  useEffect(() => {
    setPage(1);
  }, [debounceQuery]);

  return {
    transactions,
    page,
    setTransactions,
    setQuery,
    query,
    setPage,
    fetchTransations,
    totalPages,
  };
}
