import { authApi } from "../api/index";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

export default function useFetch(userType) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [orders, setOrders] = useState([]);
  const [query, setQuery] = useState("");
  const debounceQuery = useDebounce(query, 300);

  const fetchOrders = useCallback(async () => {
    try {
      const response = await authApi.get(`/order/fetchAll`, {
        params: {
          userType,
          page,
          query: debounceQuery?.length > 2 ? debounceQuery : "",
        },
      });

      if (response?.data?.totalPages) {
        setTotalPages(response?.data?.totalPages);
      }
      setOrders(response?.data?.orders);
      setTotalDocuments(response?.data?.totalDocuments);
      setCompletedOrders(response?.data?.completedOrders);
    } catch (err) {
      console.log(err);
    }
  }, [page, userType, debounceQuery]);

  // useEffect(() => {
  //   setPage(1);
  //   setTotalPages(1);
  //   setQuery("");
  // }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    setPage(1);
  }, [debounceQuery]);

  return {
    orders,
    page,
    setOrders,
    setQuery,
    completedOrders,
    totalDocuments,
    query,
    setPage,
    fetchOrders,
    totalPages,
  };
}
