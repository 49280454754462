import React from 'react'
import Congratulations from '../common/Congratulations'

const CongratulationsPop = () => {
  return (
    <div>
      <Congratulations/>
    </div>
  )
}

export default CongratulationsPop
