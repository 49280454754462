import { useCallback, useEffect, useState } from "react";
import { authApi } from "../api";

const useCategories = () => {
  const [categories, setCategories] = useState([]);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await authApi(`/offering-categories/`);
      setCategories(response?.data?.offeringCategoryList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return { categories };
};

export default useCategories;
