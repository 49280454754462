import {
  FETCH_MARKETPLACE_DATA_SUCCESS,
  FETCH_MARKETPLACE_DATA_FAILURE,
} from "../actions/marketplace.actions";

const initialState = {
  marketplaceData: [],
  error: null,
  loading: false,
};

const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARKETPLACE_DATA_SUCCESS:
      return {
        ...state,
        marketplaceData: action.payload,
        error: null,
      };
    case FETCH_MARKETPLACE_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default marketplaceReducer;
