import React from 'react';

const FindNeeds = () => {
    return (
        <>
            <div className='container'>
                <div className='flex md:flex-row flex-col-reverse justify-between items-center mt-[79px]'>
                    <div className='findNeed'>
                        <h1 className='font-[510] max-w-[542px] lg:text-[52px] text-[31px] lg:leading-[62.4px] leading-[37.2px] text-[#000000] md:mt-auto mt-[40px] text-left'>Find the right package for your needs</h1>
                        <span className='font-[400] lg:text-[24px] text-[16px] lg:leading-[28.8px] leading-[24px] text-[#5E5E5E] text-left'>Find the right package for your needs</span>
                    </div>
                    <div>
                        <img src="/assets/images/Package/Video-Thubnail.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FindNeeds;