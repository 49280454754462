import React from 'react'
import SidebarWrapper from '../layout/Sidebarwrapper'
import Index from '../Components/my-packages/Index'

const MyPackages = () => {
  return (
    <SidebarWrapper>
      <Index/>
    </SidebarWrapper>
  )
}

export default MyPackages
