import React from 'react';
import FindNeeds from './FindNeeds';
import TrustedBy from './TrustedBy';
import Subscription from './subscription';
import Testimonial from './testimonial'
import PackageFaq from './PackageFaq';
import PackageFooter from './PackageFooter';

const index = () => {
    return (
        <>
            <div className='sticky-header'>
                <div id="main-wrapper" className="main-wrapper">
                    <FindNeeds />
                    <TrustedBy />
                    <Subscription />
                    {/* <Testimonial /> */}
                    {/* <PackageFaq/> */}
                    {/* <PackageFooter/>                         */}
                </div>
            </div>
        </>
    )
}

export default index;