import React from 'react'
import { BiEditAlt } from "react-icons/bi";
import { FaRegFileAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

const AdvertiserReview = () => {
  return (
    <div className='identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]'>
      <h3 className='md:text-[20px] text-base font-[590]'>Review</h3>
      <p className='mb-5'>Your details will be used for billing and generating invoice</p>

      <div>
        <div className='flex items-center justify-between mb-4'>
          <h3 className='md:text-[24px] text-[20px] font-[590]'>Identification Details</h3>
          <span className='flex items-center blue-color'> <BiEditAlt className='text-xl' /> Edit</span>
        </div>

        <div className='border-b border-[#DBDBDB]'>
          <span className='text-[#5E5E5E]'>I represent a company</span>
          <p className='font-[510]'>Yes</p>

          <span className='text-[#5E5E5E]'>Full Name</span>
          <p className='font-[510]'>Content Distribution</p>

          <span className='text-[#5E5E5E]'>Email ID</span>
          <p className='font-[510]'>Todayq</p>

          <span className='text-[#5E5E5E]'>Country of Residence</span>
          <p className='font-[510]'>Todayq@gmail.com</p>

          <span className='text-[#5E5E5E]'>Address</span>
          <p className='font-[510]'>Description</p>
        </div>

        <div>
          <div className='flex items-center justify-between mb-4 mt-[16px]'>
            <h3 className='md:text-[24px] text-[20px] font-[590]'>Document</h3>
            <span className='flex items-center blue-color'> <BiEditAlt className='text-xl' /> Edit</span>
          </div>

          <div className='pb-[16px]'>
            <span className='text-[#5E5E5E] block mb-2'>Document Name</span>
            <div className='inline-flex border-1 overflow-hidden rounded-md border-[#DBDBDB]'>
              <span className='bg-[#D9D9D9] p-2 flex items-center'><FaRegFileAlt /></span>
              <span className='border-r border-[#DBDBDB] p-2'>File name 1234</span>
              <span className='p-2'>View</span>
            </div>
          </div>
        </div>      
        <Link to='/Marketplace' className='bg-black-color block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]'>Next</Link>
      </div>
    </div>
  )
}

export default AdvertiserReview
