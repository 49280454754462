import React, { useEffect } from "react";
import Header from "./headers/header";
import Footer from "./footers/footer";
import SideBar from "./headers/SideBar";

export default function SidebarWrapper({ children }) {
  return (
    <>
      <Header />
      <SideBar />
      {children}
      <Footer />
      {/* <ToastContainer /> */}
    </>
  );
}
