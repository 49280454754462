import React from "react";
import Index from "../../Components/expanded-view/Index";
import Header from "../../layout/headers/header";
import PackageFooter from "../../Components/package/PackageFooter";

const ExpandedView = () => {
  return (
    <div>
      <Header />
      <Index />
      <PackageFooter/>
    </div>
  );
};

export default ExpandedView;
