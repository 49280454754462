import { useEffect, useState } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom"; // Or use a different router if not using React Router

const PaymentComplete = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the animation when the component mounts
    setIsVisible(true);
  }, []);

  const handleGoHome = () => {
    navigate("/Marketplace");
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="cracker w-4 h-4 bg-yellow-400 rounded-full absolute"
            style={{
              left: `${Math.random() * 100}%`,
              bottom: `0`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${1.5 + Math.random()}s`,
            }}
          />
        ))}
      </div>
      <div className="text-gray-800 p-10 text-center z-10">
        <div
          className={`mb-6 transition-transform duration-500 ${
            isVisible
              ? "translate-y-0 opacity-100"
              : "-translate-y-10 opacity-0"
          }`}
        >
          <FaRegCircleCheck className="w-16 h-16 mx-auto text-green-300" />
        </div>
        <h1 className="text-3xl font-bold mb-4">Payment Successful!</h1>
        <p className="text-lg mb-6">
          Thank you for your payment. Your transaction has been completed
          successfully. You can now proceed to explore more!
        </p>
        <button
          onClick={handleGoHome}
          className="px-8 py-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition-all transform hover:scale-105"
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentComplete;
