import { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { TbCloudUpload } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import Billing from "./Billing";
import { Link, useNavigate } from "react-router-dom";
import useCarts from "../../hooks/useCarts";
import { authApi, authMultiFormApi } from "../../api";
import toast from "react-hot-toast";
import { MdError } from "react-icons/md";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchCartData } from "../../redux/actions/cart.actions";

const MyCart = () => {
  const navigate = useNavigate();
  const [myCart, setMyCart] = useState();
  const { carts, setCarts } = useCarts();
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const fetchCart = async () => {
    try {
      const response = await authApi.get("/carts/get");
      console.log(response?.data?.cart);

      setMyCart(response?.data?.cart);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCart();
  }, []);

  const inputRefs = useRef({});

  const handleDelete = async (id) => {
    try {
      await authApi.delete(`/carts/delete/${id}`);
      setMyCart((prev) => ({
        ...prev,
        offerings: prev?.offerings?.filter((item) => item?.id?._id !== id),
        packages: prev?.packages?.filter((item) => item?.id?._id !== id),
        addOns: prev?.addOns?.filter((item) => item?.id?._id !== id),
        addOn: prev?.addOn?.filter((item) => item?._id !== id),
        adminPackages: prev?.adminPackages?.filter(
          (item) => item?._id !== id && item?.id?._id !== id
        ),
      }));
      setCarts((prev) => ({
        ...prev,
        offerings: prev?.offerings?.filter((item) => item?.id?._id !== id),
        packages: prev?.packages?.filter((item) => item?.id?._id !== id),
        addOns: prev?.addOns?.filter((item) => item?.id?._id !== id),
        addOn: prev?.addOn?.filter((item) => item?._id !== id),
        adminPackages: prev?.adminPackages?.filter((item) => item?._id !== id),
      }));
      dispatch(fetchCartData());
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleUpload = async (type, itemId) => {
    try {
      const file = inputRefs.current[itemId]?.files[0];
      // console.log("File Input:", file);

      if (!file) {
        throw new Error("No file selected");
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);

      const response = await authMultiFormApi.post(
        `/carts/${myCart._id}/upload/${itemId}`,
        formData
      );

      fetchCart();
      console.log("Response:", response.data?.cart);
      toast.success("File uploaded successfully");
    } catch (error) {
      console.error("Upload Error:", error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
  console.log(myCart);

  useEffect(() => {
    const calculateTotal = () => {
      let sum = 0;

      myCart?.offerings
        ?.filter((i) => i?._id)
        ?.forEach((item) => {
          sum += item?.id?.contentOfferings
            ?.map((content) =>
              content?.livePrice > 0
                ? content?.livePrice
                : content?.mediaKitprice
            )
            .reduce((a, b) => Math.min(a, b));
        });

      myCart?.packages
        ?.filter((i) => i?._id || i?.id?._id)
        ?.forEach((item) => {
          // if (item?.id?.discountedAmount && item?.id?.discountedAmount !== 0) {
          //   sum += item?.id?.discountedAmount;
          // } else {

          sum += item?.id?.totalAmount;
          // }
        });
      myCart?.addOn
        ?.filter((i) => i?._id)
        ?.forEach((item) => {
          if (item?.livePrice && item?.livePrice !== 0) {
            sum += item?.livePrice;
          } else {
            sum += item?.mediaKitprice;
          }
        });

      myCart?.adminPackages
        ?.filter((i) => i?._id || i?.id)
        ?.forEach((item) => {
          // if (item?.id?.livePrice && item?.id?.livePrice > 0) {
          //   sum += item?.id?.livePrice;
          // } else {
          sum += item?.id?.discountedPrice;
          // }
        });
      setTotal(sum);
    };

    calculateTotal();
  }, [myCart]);

  return (
    <div className="md:px-[32px] px-[12px] py-[24px]">
      <div className="flex items-center mb-5">
        <button className="text-black">
          <FaArrowLeft
            onClick={() => {
              navigate("/Marketplace");
            }}
            className="me-3"
          />
        </button>
        <span className="md:text-[20px] font-[510] text-md-start text-center w-100">
          My Cart
        </span>
      </div>
      <div className="d-lg-none">
        <div className="bg-[#FFF3C7] mb-4 d-flex gap-2 rounded border-l-2 border-[#EEC800] p-3">
          <span className="font-[590]">Note:</span>
          <p className="text-[#5E5E5E]">
            Please verify that the information you introduced is correct. Once
            you submit your order and the payment is confirmed, one of our team
            member will contact you within 3 business days.
          </p>
        </div>
      </div>
      <div className="row gy-4">
        <div className="col-lg-6">
          <div className="bg-white border-1 border-[#DBDBDB] md:p-6 p-3 rounded-lg">
            <p className="font-[590]">Items</p>

            {myCart?.offerings?.length > 0 ||
              myCart?.packages?.length > 0 ||
              myCart?.adminPackages?.length > 0 ||
              myCart?.addOns?.length > 0 ? (
              <>
                {myCart?.offerings
                  ?.filter((i) => i?._id)
                  ?.map((item) => (
                    <div
                      key={item?.id?._id}
                      className="bg-[#F9F9F9] p-4 mb-3 rounded-2xl d-md-flex gap-2 justify-between"
                    >
                      {/* {console.log("offering", item)} */}
                      <div className="d-lg-flex justify-between">
                        <div className="d-flex items-start gap-3">
                          <img
                            src={
                              item?.id?.basicDetails?.["Website LOGO"] ||
                              "/assets/images/Home-Page/dodo.png"
                            }
                            alt="dodo"
                          />
                          <div>
                            <p className="font-[590] capitalize md:text-base text-[13px] mb-0">
                              {item?.id?.basicDetails?.websiteName}
                            </p>
                            <p className="text-[#5E5E5E] md:text-base text-[13px] mb-0">
                              {item?.id?.basicDetails?.websiteDescription}
                            </p>
                            <span className="md:text-xl font-[590]">
                              $
                              {item?.id?.contentOfferings
                                ?.map((content) =>
                                  content?.livePrice > 0
                                    ? content?.livePrice
                                    : content?.mediaKitprice
                                )
                                .reduce((a, b) => Math.min(a, b))}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-10">
                        <div className="d-flex justify-content-between flex-lg-column justify-content-lg-center text-center mt-lg-0 mt-3    ">
                          <input
                            type="file"
                            className="hidden"
                            ref={(ref) =>
                              (inputRefs.current[item.id._id] = ref)
                            }
                            onChange={() =>
                              handleUpload("offerings", item.id._id)
                            }
                          />

                          <button
                            onClick={() => {
                              inputRefs.current[item.id._id]?.click();
                            }}
                            className="no-underline d-flex items-center gap-1 rounded bg-[#E4E4E478] px-4 py-[6px] text-black text-[13px] w-full"
                          >
                            <TbCloudUpload />
                            Upload Doc
                          </button>
                          {/* <span>or</span>
                        <a
                          href="#"
                          className="d-flex items-center gap-1 text-black rounded no-underline bg-[#E4E4E478] text-[13px]  px-4 py-[6px]"
                        >
                          <Form.Check type="checkbox" />
                          Get it written <MdError className="text-[#808080]" />
                        </a> */}
                        </div>
                        <IoClose
                          className="cursor-pointer"
                          onClick={() => {
                            console.log(item);

                            handleDelete(item?.id?._id || item?.id);
                          }}
                        />
                      </div>
                    </div>
                  ))}

                {myCart?.packages
                  ?.filter((i) => i?.id)
                  ?.map((item) => (
                    <div
                      key={item?.id?._id}
                      className="bg-[#F9F9F9] p-md-4 p-2 mb-3 rounded-xl d-md-flex gap-2 justify-between relative"
                    >
                      <div className="d-lg-flex justify-between">
                        <div className="d-flex items-start gap-3">
                          <img
                            src="/assets/images/Home-Page/dodo.png"
                            alt="dodo"
                          />
                          <div>
                            <p className="font-[590] capitalize md:text-base text-[13px] mb-0">
                              {`Package: ` + item?.id?.name}
                            </p>
                            <p className="text-[#5E5E5E] md:text-base text-[13px] mb-0">
                              {item?.id?.additionalDetails}
                            </p>
                            <div className="flex gap-1">
                              <div className="md:text-xl font-[590]">
                                {/* {item?.id?.discountedAmount &&
                                item?.id?.discountedAmount !== 0 ? (
                                  <p className="line-through pr-2">
                                    ${item?.id?.totalAmount}
                                  </p>
                                ) : ( */}
                                <p>${item?.id?.totalAmount}</p>
                                {/* )} */}
                              </div>
                              {/* {item?.id?.discountedAmount &&
                                item?.id?.discountedAmount !== 0 && (
                                  <div className="md:text-xl gap-1">
                                    ${item?.id?.discountedAmount}
                                  </div>
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-10">
                        <div className="d-flex flex-grow justify-content-between items-center gap-2 flex-lg-column justify-content-lg-center text-center mt-lg-0 mt-3">
                          <input
                            className="hidden"
                            type="file"
                            ref={(ref) => (inputRefs.current[item.id?._id] = ref)}
                            onChange={() =>
                              handleUpload("packages", item.id?._id)
                            }
                          />

                          <button
                            className="no-underline d-flex flex-grow justify-center items-center gap-1 rounded-[3px] bg-[#E4E4E478] px-md-4 px-2 py-[6px] text-black text-[13px]"
                            onClick={() => {
                              inputRefs.current[item.id._id].click();
                            }}
                          >
                            <TbCloudUpload />
                            Upload Doc
                          </button>
                          <span className="text-sm inline-block my-1">or</span>
                          <a
                            href="#"
                            className="d-flex items-center gap-1 flex-grow justify-center text-black rounded-[3px] no-underline bg-[#E4E4E478] text-[13px] px-md-4 px-2 py-[6px]"
                          >
                            <input type="checkbox" className="w-[14px] h-[14px]"/>
                            Get it written <MdError className="text-[#808080]" />
                          </a>
                        </div>
                        <IoClose
                          className="cursor-pointer absolute top-2 right-2"
                          onClick={() => {
                            handleDelete(item?.id?._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}

                {myCart?.addOn
                  ?.filter((i) => i?._id)
                  ?.map((item, idx) => (
                    <div
                      key={item?._id}
                      className="bg-[#F9F9F9] p-4 mb-3 rounded-2xl d-flex   gap-2 justify-between"
                    >
                      <div className="d-lg-flex justify-between">
                        <div className="d-flex items-start gap-3">
                          <img
                            src="/assets/images/Home-Page/dodo.png"
                            alt="dodo"
                          />
                          <div>
                            <p className="font-[590] whitespace-nowrap md:text-base text-[13px] mb-0">
                              {item?.typeId?.name}
                            </p>
                            {/* <p className="text-[#5E5E5E] md:text-base text-[13px] mb-0">
                        Description praesent nisl vel neque. Suspendisse urna in{" "}
                      </p> */}
                            <div className="flex gap-1  ">
                              <div className="md:text-xl  font-[590]">
                                {/* {item?.mediaDiscountedKitPrice &&
                              item?.mediaDiscountedKitPrice !== 0 ? (
                                <p className="line-through pr-2">
                                  ${item?.mediaKitprice}
                                </p>
                              ) : ( */}
                                {/* <p>
                                  $
                                  {item?.livePrice > 0
                                    ? item?.livePrice
                                    : item?.mediaKitprice}
                                </p> */}
                                {/* )} */}
                              </div>
                              {/* {item.mediaDiscountedKitPrice &&
                                item.mediaDiscountedKitPrice !== 0 && ( */}
                              <div className="md:text-xl gap-1 font-[590]">
                                $
                                {item?.livePrice > 0
                                  ? item?.livePrice
                                  : item?.mediaKitprice}
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-10">
                        <div className="d-flex justify-content-between flex-lg-column justify-content-lg-center text-center mt-lg-0 mt-3 w-full   ">
                          <input
                            className="hidden"
                            type="file"
                            ref={(ref) => (inputRefs.current[item?._id] = ref)}
                            onChange={() => handleUpload("addOns", item?._id)}
                          />

                          <div className="flex gap-2">
                            {myCart?.addOns[idx]?.uploadedDoc && (
                              <img
                                src={`${process.env.REACT_APP_ASSET_URL}/${myCart?.addOns[idx]?.uploadedDoc}`}
                                alt="uploaded img"
                                className="w-10 h-10"
                              />
                            )}
                            <button
                              onClick={() => {
                                inputRefs.current[item?._id]?.click();
                              }}
                              className="no-underline d-flex items-center gap-1 rounded bg-[#E4E4E478] px-4 py-[6px] text-black text-[13px] w-full"
                            >
                              <TbCloudUpload />
                              Upload Doc
                            </button>
                          </div>

                          <span>or</span>
                          <Link className="d-flex items-center gap-2 justify-center text-black rounded no-underline bg-[#E4E4E478] text-[13px]  px-4 py-[6px] w-full">
                            <Form.Check type="checkbox" />
                            Get it written{" "}
                            <MdError className="text-[#808080]" />
                          </Link>
                        </div>
                        <IoClose
                          className="cursor-pointer"
                          onClick={() => {
                            handleDelete(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}

                {myCart?.adminPackages
                  ?.filter((i) => i?.id)
                  ?.map((item) => (
                    <div
                      key={item?.id?._id}
                      className="bg-[#F9F9F9] p-4 mb-3 rounded-2xl d-flex gap-2 justify-between"
                    >
                      <div className="d-lg-flex    justify-between">
                        <div className="d-flex items-start gap-3 lg:w-[60%]">
                          <img
                            src="/assets/images/Home-Page/dodo.png"
                            alt="dodo"
                          />
                          <div>
                            <p className="font-[590] capitalize md:text-base text-[13px] mb-0">
                              {`Package: ` + item?.id?.displayName}
                            </p>
                            {/* <p className="text-[#5E5E5E] md:text-base text-[13px] mb-0">
                            {item?.id?.additionalDetails}
                          </p> */}
                            <div className="flex gap-1  ">
                              <div className="md:text-xl  font-[590]">
                                {item?.id?.price > 0 &&
                                  item?.id?.discountedPrice > 0 ? (
                                  <div className="flex">
                                    <p className="line-through pr-2">
                                      ${item?.id?.price}
                                    </p>
                                    <p>
                                      {" "}
                                      $
                                      {item?.id?.discountedPrice > 0
                                        ? item?.id?.discountedPrice
                                        : item?.id?.price}
                                    </p>
                                  </div>
                                ) : (
                                  <>${item?.id?.discountedPrice}</>
                                )}
                              </div>
                              {item?.id?.discountedAmount &&
                                item?.id?.discountedAmount !== 0 && (
                                  <div className="md:text-xl gap-1">
                                    ${item?.id?.discountedAmount}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-10">
                        {/* <div className="d-flex justify-content-between flex-lg-column justify-content-lg-center text-center mt-lg-0 mt-3    ">
                        <input
                          className="hidden"
                          type="file"
                          ref={(ref) => (inputRefs.current[item.id?._id] = ref)}
                          onChange={() =>
                            handleUpload("adminPackages", item.id?._id)
                          }
                        />

                        <button
                          className="no-underline d-flex items-center gap-1 rounded bg-[#E4E4E478] px-4 py-[6px] text-black text-[13px]"
                          onClick={() => {
                            inputRefs.current[item.id._id].click();
                          }}
                        >
                          <TbCloudUpload />
                          Upload Doc
                        </button>
                        <span>or</span>
                        <a
                          href="#"
                          className="d-flex items-center gap-1 text-black rounded no-underline bg-[#E4E4E478] text-[13px]  px-4 py-[6px]"
                        >
                          <Form.Check type="checkbox" />
                          Get it written <MdError className="text-[#808080]" />
                        </a>
                      </div> */}
                        <IoClose
                          className="cursor-pointer"
                          onClick={() => {
                            handleDelete(item?.id?._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div className="text-bold text-center text-lg">
                No items in cart
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <Billing total={total} cartId={myCart?._id} />
        </div>
      </div>
    </div>
  );
};

export default MyCart;
