import React from 'react'
import Index from '../../Components/Advertiser/advertiser-verification/Index'

const AdvertiserVerification = () => {
  return (
    <div>
      <Index/>
    </div>
  )
}

export default AdvertiserVerification
