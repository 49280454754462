import React from 'react'
import SignIn from '../../Components/Advertiser/SignIn'

const AdvertiserSignIn = () => {
  return (
    <div>
      <SignIn/>
    </div>
  )
}

export default AdvertiserSignIn
