import React from "react";
import Index from "../Components/sign-up/Index";

const SignUp = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default SignUp;
