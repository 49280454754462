import React, { useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";

const Notification = ({ className, text, status }) => {
  const [isVisible, setIsVisible] = useState(text?.message ?? true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div
        className={`flex gap-3 justify-between  items-center ${
          status ? "bg-[#DDF2DF]  " : "bg-[#F2DDDD]"
        } border text-black  rounded-lg shadow-md py-2 px-2 z-50 ${className}`}
      >
        {status ? (
          <FaRegCircleCheck className="text-[#1A8200] text-[20px]" />
        ) : (
          <BiErrorCircle className="text-red-700 text-[24px]" />
        )}
        <div className="text-lg">
          <p className="my-0">{text}</p>
        </div>

        <button
          className=" text-gray-600 hover:text-gray-800 text-[20px] "
          onClick={handleClose}
        >
          ×
        </button>
      </div>
    )
  );
};

export default Notification;
