import axios from "axios";
import { BASEURL } from "../../baseUrl";
import { authApi } from "../../api";

export const FETCH_MARKETPLACE_DATA_SUCCESS = "FETCH_MARKETPLACE_DATA_SUCCESS";
export const FETCH_MARKETPLACE_DATA_FAILURE = "FETCH_MARKETPLACE_DATA_FAILURE";

export const fetchMarketplaceData = () => {
  return async (dispatch) => {
    try {
      const response = await authApi.get(`/offerings/my`);
      dispatch({
        type: FETCH_MARKETPLACE_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_MARKETPLACE_DATA_FAILURE,
        error: error.message,
      });
    }
  };
};
