import React from 'react'
import AdvertiserVerify from './AdvertiserVerify'

const Index = () => {
  return (
    <div>
        <AdvertiserVerify/>
    </div>
  )
}

export default Index
