import axios from "axios";
import { BASEURL } from "../baseUrl";

const api = axios.create({
  baseURL: BASEURL,
});
export default api;

export const authApi = axios.create({
  baseURL: BASEURL,
});
export const authMultiFormApi = axios.create({
  baseURL: BASEURL,
});

authApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
authMultiFormApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    config.headers["content-type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
