import React from "react";
import { SideBar } from "../../layout";
import { useNavigate } from "react-router-dom";

export default function DashboardHeader() {
  const navigate = useNavigate();
  const redirectPage = (url) => {
    navigate(url);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="font-[510] text-[31px] leading-[37.2px] mb-0 text-[#000000]">
          Publishers Dashboard
        </h1>
        {user?.userType === "publisher" && (
          <div>
            <button
              className="font-[500] text-[20px] text-[#FFFFFF] border-1 border-[#0A0A0A] bg-[#0A0A0A] py-[8px] px-[20px] gap-[10px] rounded-[5px] me-2"
              onClick={() => {
                redirectPage("/AddOffering");
              }}
            >
              + Add Offering
            </button>
            <button
              className="font-[500] text-[20px] border-1 border-[#0A0A0A] py-[8px] px-[20px] gap-[10px] rounded-[5px]"
              onClick={() => {
                redirectPage("/create-package");
              }}
            >
              Create Package
            </button>
          </div>
        )}
      </div>
    </>
  );
}
