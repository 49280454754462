import React from 'react'
import Index from '../Components/account-varification/Index'

const AccountVerification = () => {
  return (
    <div>
      <Index/>
    </div>
  )
}

export default AccountVerification
