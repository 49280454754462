import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useGeo from "../../hooks/useGeo";
import { useMediaQuery } from "react-responsive";

export default function MarketplaceSideBar({
  categories,
  onCategorySelect,
  selectedCategories,
  handleFilter,
  selectedLanguage,
  selectedFilter,
  setSelectedFilter,
  setSelectedLanguage,
  selectedPrice,
  setSelectedPrice,
  setFilterDropDown,
  seeAllfilters,
}) {
  const { lang } = useGeo();
  const [searchTerm, setSearchTerm] = useState("");

  const budget = [
    { id: 0, name: "$0-$100" },

    { id: 1, name: "$100-$500" },

    { id: 2, name: "$500-$1000" },

    { id: 3, name: "$1000-$5000" },
    { id: 4, name: "$5000+" },
  ];

  const filters = [
    { id: 0, name: "Gambling", value: "gambling" },
    { id: 1, name: "Adult Content", value: "adultContent" },
    { id: 2, name: "Cryptocurrency", value: "crypto" },
  ];

  const filteredLanguages = lang
  ?.filter((language) =>
    language.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.name.localeCompare(b.name));


  // const islg = useMediaQuery({
  //   query: "(min-width: 1024px)",
  // });
  // const isxl = useMediaQuery({ query: "(min-width: 1280px)" });
  const is2xl = useMediaQuery({ query: "(max-width: 1536px)" });

  return (
    <>
      <div className="sticky top-[90px]">
        <div className="marketplace-Categories">
          <Accordion
            defaultActiveKey="0"
            className="!border-[#DBDBDB] rounded-[8px]"
          >
            <Accordion.Item eventKey="0" className="!py-[20px] !px-[12px]">
              <Accordion.Header className="font-[590] text-[16px] leading-[19.2px]">
                Categories
              </Accordion.Header>
              <Accordion.Body className="visible !p-0">
                <Form className="h-[332px] overflow-auto !mt-[20px]">
                  {categories?.map((category) => (
                    <Form.Group className="mb-3" key={category?._id}>
                      <Form.Check
                        type="checkbox"
                        label={category?.name}
                        checked={selectedCategories.includes(category?.name)}
                        className="font-[510] text-[13px] leading-[15.6px]"
                        onChange={() => {
                          onCategorySelect(category?.name);
                          
                        }}
                      />
                    </Form.Group>
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="marketplace-language mt-[8px]">
          <Accordion className="!border-[#DBDBDB] rounded-[8px]">
            <Accordion.Item eventKey="0" className="!py-[20px] !px-[12px]">
              <Accordion.Header className="font-[590] text-[16px] leading-[19.2px]">
                Region
              </Accordion.Header>
              <Accordion.Body className="visible !p-0 !mt-[20px]">
                <Form className="h-[332px] overflow-auto">
                  <Form.Group className="mb-3  mx-1 xl:mx-2">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-3"
                    />
                  </Form.Group>
                  {Array.isArray(filteredLanguages) &&
                    filteredLanguages.map((language, index) => (
                      <Form.Group
                        key={language._id || index}
                        className="mb-3"
                        id="formGridCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label={language.name}
                          className="font-[510] text-[13px] leading-[15.6px]"
                          onChange={(e) => {  
                            if (e.target.checked) {
                              setSelectedLanguage((prev) => [
                                ...prev,
                                language.name,
                              ]);
                            } else {
                              setSelectedLanguage((prev) =>
                                prev.filter((lang) => lang !== language.name)
                              );
                            }
                          }}
                        />
                      </Form.Group>
                    ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {/* <div className="marketplace-region mt-[8px]">
          <Accordion className="!border-[#DBDBDB] rounded-[8px]">
            <Accordion.Item eventKey="0" className="!py-[20px] !px-[12px]">
              <Accordion.Header className="font-[590] text-[16px] leading-[19.2px] ">
                Region
              </Accordion.Header>
              <Accordion.Body className="visible !p-0 !mt-[20px]">
                <Form className="h-[332px] overflow-auto">
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Content Distribution"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Ads"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Twitter Influencers"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Telegram Influencers"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Youtube Influencers"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Instagram Influencer"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="ICO Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Exchange Listing"
                      className="font-[510] text-[13px] leading-[15.6px]"
                    />
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}

        <div className="marketplace-Budget mt-[8px]">
          <Accordion
            // defaultActiveKey="0"
            className="!border-[#DBDBDB] rounded-[8px]"
          >
            <Accordion.Item eventKey="0" className="!py-[20px]  !px-[12px]">
              <Accordion.Header className="font-[590]  text-[16px] leading-[19.2px] ">
                Budget
              </Accordion.Header>
              <Accordion.Body className="visible !p-0  !mt-[20px]">
                <fieldset>
                  <Form.Group as={is2xl ? undefined : Row} className="mb-3 ">
                    <Col key={budget.id} sm={12}>
                      <Form.Check
                        type="radio"
                        label="None"
                        name="price"
                        id="first"
                        className="font-[510] text-[13px]  leading-[15.6px]"
                        // checked={selectedPrice === budget?.name}
                        onChange={() => {
                          setSelectedPrice(null);
                        }}
                      />
                    </Col>
                    {budget?.map((budget) => (
                      <Col key={budget.id} sm={12}>
                        <Form.Check
                          type="radio"
                          label={budget?.name}
                          name="price"
                          id="first"
                          className="font-[510] text-[13px]    leading-[15.6px]"
                          checked={selectedPrice === budget?.name}
                          onChange={() => {
                            setSelectedPrice(budget?.name);
                          }}
                        />
                      </Col>
                    ))}
                  </Form.Group>
                </fieldset>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="marketplace-Categories mt-[8px]">
          <Accordion
            // defaultActiveKey="0"
            className="!border-[#DBDBDB] rounded-[8px]"
          >
            <Accordion.Item eventKey="0" className="!py-[20px] !px-[12px]">
              <Accordion.Header className="font-[590] text-[16px] leading-[19.2px]">
                Filters
              </Accordion.Header>
              <Accordion.Body className="visible !p-0">
                <Form className=" overflow-auto !mt-[20px]">
                  {filters.map((filter) => (
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label={filter.name}
                        checked={selectedFilter.includes(filter.value)}
                        className="font-[510] text-[13px] leading-[15.6px]"
                        onChange={() => handleFilter(filter.value)}
                      />
                    </Form.Group>
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}
