import React from "react";
import AccountVerify from "./AccountVerify";
import { Wrapper } from "../../layout";

const Index = () => {
  return (
    <Wrapper>
      <AccountVerify />
    </Wrapper>
  );
};

export default Index;
