import React from 'react'
import SignUp from '../../Components/Advertiser/SignUp'

const AdvertiserSignUp = () => {
  return (
    <div>
        <SignUp/>
    </div>
  )
}

export default AdvertiserSignUp
