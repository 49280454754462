import React, { useEffect } from "react";
import { PiArrowSquareOutLight } from "react-icons/pi";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoCartOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const BannerExample1 = ({ state }) => {
  
  return (
    <div
      className="offcanvas example !h-[80vh] px-md-5 overflow-hidden offcanvas-bottom rounded-[24px_24px_0_0]"
      tabindex="-1"
      id="offcanvasBottom1"
      aria-labelledby="offcanvasBottomLabel"
    >
      <div className="offcanvas-header">
        <h5
          className="offcanvas-title text-center mx-auto font-[590] md:text-[30px] text-[20px]"
          id="offcanvasBottomLabel"
        >
          Case Study
        </h5>
        <button
          type="button"
          className="btn-close shadow-none !rounded-full !bg-[#FFFFFF80] absolute top-[-30px] right-[20px]"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body small ">
        <div className="row gy-4 justify-center ">
          <OwlCarousel className="owl-theme w-full " loop margin={10} items={1}>
            {state?.[`files[]`] && state?.[`files[]`].length > 0 ? (
              state?.[`files[]`].map((screenshot, index) => (
                <div key={index} className="item !h-[70vh]">
                  <img
                    className="h-[100%] w-[100%] object-contain"
                    src={`${process.env.REACT_APP_ASSET_URL}/${screenshot}`}
                    alt={screenshot}
                  />
                </div>
              ))
            ) : (
              <p className="font-extrabold text-2xl text-center mx-auto">
                No Case Study
              </p>
            )}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default BannerExample1;
