import React, { useRef, useState } from 'react'
import { IoMdInformationCircleOutline } from "react-icons/io";
import Accordion from 'react-bootstrap/Accordion';

const introduction = () => {

    return (
        <>
            <div className='flex justify-between items-center'>
                <img src="/assets/images/Home-Page/thumbnail.png" className='w-[100%] h-[100%]' alt="" />
            </div>

            <div className='mt-2'>
                <h1 className='font-[400] text-[22.92px] leading-[27.5px] text-[#242424]'>Introduction</h1>
                <p className='font-[400] text-[14px] leading-[18.98px] text-[#2D2D2D]'>Need help building a scope of work? One of representatives can assist you build one.</p>
            </div>

            <button className='font-[510] text-[18px] !text-[#FFFFFF] py-[10px] px-[20px] bg-[#0A0A0A] w-[100%] rounded-[5px]'>Need Assistance</button>

        </>
    )
}

export default introduction
