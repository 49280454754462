import React, { useEffect, useMemo, useState } from "react";
import { FaArrowLeft, FaXTwitter } from "react-icons/fa6";
import { PiArrowSquareOutLight } from "react-icons/pi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GrFacebookOption } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import { IoCartOutline, IoLogoLinkedin } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import PopluarPackage from "./PopluarPackage";
import PressRelease from "./PressRelease";
import MarketplaceBrand from "../Marketplace/MarketplaceBrand.jsx";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import useCategories from "../../hooks/useCategories.js";
import BannerExample1 from "./BannerExample1.js";
import { authApi } from "../../api/index.js";
import toast from "react-hot-toast";

const TodayqNews = () => {
  const location = useLocation();
  // const { state } = location;
  const [state, setState] = useState({});
  const { userId, offeringId } = useParams();
  const [isAdvertiser, setIsAdvertiser] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(offeringId);
  const navigate = useNavigate();
  const storeUserData = useSelector((state) => state.authentication.user);
  const user = JSON.parse(localStorage.getItem("user")) || storeUserData;

  const { categories } = useCategories();
  useEffect(() => {
    if (!user?.userType) {
      return;
    }

    if (user?.userType === "publisher") setIsAdvertiser(false);
    else setIsAdvertiser(true);
  }, [isAdvertiser, user]);
  const cartData = useSelector((state) => state.cart.cartItems);

  const countCart = useMemo(() => {
    if (!cartData) return 0;

    const allItems = [
      ...(cartData.adminPackages || []),
      ...(cartData.offerings || []),
      ...(cartData.addOn || []),
      ...(cartData.packages || []),
    ];

    return allItems.reduce((acc, item) => {
      if (item._id || item.id) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [cartData]);

  const handleSelectedContentOffering = async (selectedPackage) => {
    try {
      try {
        if (!userId && !selectedPackage) {
          return;
        }

        const response = await authApi.get(`offerings/particular`, {
          params: {
            status: "approved",
            userId: userId,
            offeringCategoryId: offeringId,
          },
        });

        if (response.status !== "failed") {
          setState({ ...response?.data?.offeringList });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(`No Offering found`);
        navigate(-1);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleSelectedContentOffering(offeringId);
    setSelectedPackage(offeringId);
  }, [offeringId]);

  const handleCopyUrl = () => {
    const currentUrl = window.location.href; // Get the current browser URL
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        toast.success("Browser URL copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy URL.");
      });
  };

  return (
    <div className="today_news">
      <div className="md:px-[32px] px-[12px] py-[24px]">
        <div className="flex justify-between">
          <div className="flex items-center mb-5">
            <button
              onClick={() => navigate("/Marketplace")}
              className="text-black"
            >
              <FaArrowLeft className="me-3" />
            </button>
            <h3 className="font-[510] lg:text-[24px] text-[18px] mb-0">Back to Marketplace</h3>
          </div>
          {isAdvertiser && (
            <Link to="/my-cart" className="relative">
              <IoCartOutline className="text-[32px] text-[#5E5E5E]" />
              <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                {countCart}
              </span>
            </Link>
          )}
        </div>

        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <div className="bg-white shadows mb-4 p-md-4 p-3 rounded-lg border-1 border-[#DBDBDB]">
              <div className="d-flex justify-between items-center mb-4">
                <div className="d-flex items-center gap-3">
                  <img
                    src={
                      typeof state?.basicDetails?.["Website LOGO"]?.[0] ===
                        "string"
                        ? `${process.env.REACT_APP_ASSET_URL}/${state?.basicDetails?.["Website LOGO"]?.[0]}`
                        : "/assets/images/Home-Page/marketPlace1.png"
                    }
                    alt=""
                    className="w-12 h-12 rounded-full"
                  // Replace with offering basic details image if available
                  />
                  <div>
                    <h2 className="font-[590] md:text-[24px] text-[20px]">
                      {state?.basicDetails?.websiteName ?? "No Name"}
                    </h2>
                    <span className="text-[13px] font-[510] flex items-center gap-1">
                      {state?.basicDetails?.websiteURL && (
                        <a
                          target="_blank"
                          href={state?.basicDetails?.websiteURL}
                          rel="noreferrer"
                          className="text-[#5E5E5E] !no-underline"
                        >
                          Visit website
                        </a>
                      )}
                      {state?.basicDetails?.websiteURL && (
                        <img src="/assets/images/marketplace/arrow.svg" alt="arrow" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-md-flex d-none items-end gap-2">
                  {state?.basicDetails ? (
                    <button
                      key="case-study"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom1"
                      className="bg-black border outline-none rounded-lg px-[20px] h-[46px] text-white"
                      disabled={!state}
                    >
                      See Media Kit
                    </button>
                  ) : (
                    <button
                      key="fake"
                      className="bg-black border outline-none rounded-lg px-[20px] h-[46px] text-white"
                      onClick={() => {
                        toast.error("Please Wait...");
                      }}
                    >
                      See Media Kit
                    </button>
                  )}
                  <div>
                    <select
                      className="border-[1px] border-[#229ED9] shadow-[0px_4px_28px_#0000001C] outline-none rounded-lg px-[20px] h-[46px] "
                      value={
                        categories &&
                        categories?.find((i) => {
                          return i.name === selectedPackage;
                        })?._id
                      }
                      onChange={(e) => {
                        const selectedOption = e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("data-selectedOption");
                        navigate(`/partner/${userId}/${selectedOption}`);
                      }}
                    >
                      <option value="" key="" disabled>
                        Choose Category
                      </option>
                      {categories?.map((category) => (
                        <option
                          value={category?._id}
                          key={category?._id}
                          data-selectedOption={category?.name}
                        >
                          {category?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {state?.basicDetails && (
                    <BannerExample1 state={state?.basicDetails} />
                  )}

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCopyUrl();
                    }}
                  >
                    <img src="/assets/images/expanded/share.png" alt="" />
                  </div>
                  {/* <div>
                    <img src="/assets/images/expanded/save.png" alt="" />
                  </div> */}
                </div>
                <div className="d-md-none">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCopyUrl();
                    }}
                  >
                    <img src="/assets/images/expanded/share.png" alt="" />
                  </div>
                  {/* <BsThreeDotsVertical className="text-[24px]" /> */}
                </div>
              </div>
              <div className="d-flex items-center gap-3 border-b pb-3 mb-3">
                <div className="bg-[#F0EBFD] border-1 w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px]">Visitors</span>
                  <p className="mb-0 font-[590] text-[20px]">1.5M</p>
                </div>
                <div className="bg-[#EAF6FD] border-1 md:w-[200px] w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px] d-md-block d-none">Combined Social Community</span>
                  <span className="font-[510] text-[13px] d-md-none">Social</span>
                  <p className="mb-0 font-[590] text-[20px]">4.9M</p>
                </div>
                <div className="bg-[#EAF7F0] border-1 w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px]">KPR Metric</span>
                  <p className="mb-0 font-[590] text-[20px]">
                    {state?.kprMetric}/ 10
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <h3 className="md:text-[20px] text-base font-[590] mb-4">
                  {state?.basicDetails?.websiteName}
                </h3>
                <p className="mb-0">
                  {state?.basicDetails?.websiteDescription}
                </p>
              </div>
              <div className="row gy-4">
                <div className="col-md-3">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-3 mb-2">
                    Social Media
                  </h3>
                  <div className="flex items-center  flex-wrap text-xl gap-2 text-[#434343]">
                    {state?.basicDetails?.socialLinks?.map((link) => {
                      switch (link?.name) {
                        case "Facebook":
                          return (
                            <GrFacebookOption
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "X":
                          return (
                            <FaXTwitter
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "Youtube":
                          return (
                            <FaYoutube
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "Instagram":
                          return (
                            <AiFillInstagram
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "LinkedIn":
                          return (
                            <IoLogoLinkedin
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "WhatsApp":
                          return (
                            <IoLogoWhatsapp
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        case "Telegram":
                          return (
                            <FaTelegramPlane
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        case "TikTok":
                          return (
                            <IoLogoTiktok
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-3 mb-2">
                    Regions
                  </h3>
                  <p className="mb-0 text-[15px]">
                    {Array.isArray(state?.basicDetails?.geoLocations) &&
                      state?.basicDetails?.geoLocations?.join(", ")}
                  </p>
                </div>
                <div className="col-md-4">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-3 mb-2">
                    Languages
                  </h3>
                  <p className="mb-0 capitalize">
                    {state?.basicDetails?.contentLanguage}
                  </p>
                </div>
                <div className="d-md-none d-flex gap-2">
                  {state?.basicDetails ? (
                    <button
                      key="case-study"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom1"
                      className="bg-black border outline-none rounded-lg px-[14px] h-[46px] text-white"
                      disabled={!state}
                    >
                      See Media Kit
                    </button>
                  ) : (
                    <button
                      key="fake"
                      className="bg-black border outline-none rounded-lg px-[14px] h-[46px] text-white"
                      onClick={() => {
                        toast.error("Please Wait...");
                      }}
                    >
                      See Media Kit
                    </button>
                  )}
                  <div className="flex-grow md:flex-grow-0">
                    <select
                      className="border-[1px] border-[#229ED9] shadow-[0px_4px_28px_#0000001C] outline-none rounded-lg px-[14px] h-[46px] "
                      value={
                        categories &&
                        categories?.find((i) => {
                          return i.name === selectedPackage;
                        })?._id
                      }
                      onChange={(e) => {
                        const selectedOption = e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("data-selectedOption");
                        navigate(`/partner/${userId}/${selectedOption}`);
                      }}
                    >
                      <option value="" key="" disabled>
                        Choose Category
                      </option>
                      {categories?.map((category) => (
                        <option
                          value={category?._id}
                          key={category?._id}
                          data-selectedOption={category?.name}
                        >
                          {category?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <PopluarPackage
              isAdvertiser={isAdvertiser}
              selectedPackage={selectedPackage}
              userId={userId}
            />

            <PressRelease isAdvertiser={isAdvertiser} state={state} />
          </div>
          <div className="col-xl-3 col-lg-4">
            <MarketplaceBrand />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayqNews;
