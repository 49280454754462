import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import IdentificationDetails from "./IdentificationDetails";
import UploadDocuments from "./UploadDocuments";
import WithdrawalOption from "./WithdrawalOption";
import Review from "./Review";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../redux/actions/auth.action";
import { useNavigate } from "react-router-dom";
import { authApi } from "../../api";

const AccountVerify = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [selectedStep, setSelectedStep] = useState("Identification Details");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isUserType, setIsUserType] = useState(false);
  const { userType, formData } = useSelector((state) => state.authentication);
  const [steps, setSteps] = useState([
    "Identification Details",
    "Upload Documents",
    "Review",
  ]);

  useEffect(() => {
    if (formData) {
      setSteps([
        "Identification Details",
        "Upload Documents",
        ...(formData.userType === "publisher" ? ["Withdrawal Option"] : []),
        "Review",
      ]);
    }
  }, [formData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (userType) {
      setIsUserType(true);
    }
  }, [userType]);
  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await authApi.get("/auth/fetch");
        setUserData(response?.data?.user);
        dispatch(updateFormData(response?.data?.user));

        if (response?.data?.user?.isAdminVerified) {
          setCompletedSteps([
            "Identification Details",
            "Upload Documents",
            "Withdrawal Option",
            "Review",
          ]);
          setSelectedStep("Review");
        } else {
          if (!response?.data?.user.hasOwnProperty("isCompany")) {
            setSelectedStep("Identification Details");
          } else {
            setCompletedSteps([
              "Identification Details",
              "Upload Documents",
              "Withdrawal Option",
            ]);
            setSelectedStep("Review");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchUserData();
  }, []);
  const handleNext = () => {
    const currentIndex = steps.indexOf(selectedStep);
    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex + 1];
      setSelectedStep(nextStep);
      if (!completedSteps.includes(selectedStep)) {
        setCompletedSteps([...completedSteps, selectedStep]);
      }
    }
  };

  return (
    <div className="account-verify">
      <div className="md:px-[32px] px-[12px] py-[24px]">
        <div className="flex items-center mb-5">
          <button onClick={() => navigate(-1)} className="text-black">
            <FaArrowLeft className="me-3" />
          </button>
          <span className="d-lg-block d-none font-[590] md:text-[20px]">
            Account Verification
          </span>
          <span className="d-lg-none font-[590] flex-grow text-center">
            Account Details
          </span>
        </div>
        <div className="row gy-4">
          <div className="col-lg-3">
            <div className="bg-white border-1 border-[#DBDBDB] rounded-lg p-3">
              <h3 className="text-[20px] font-[590] d-lg-block d-none">
                Account Details
              </h3>
              <p className="text-sm font-[510] border-b border-[#DBDBDB] pb-2">
                Current Status :{" "}
                {userData?.isAdminVerified === true ? (
                  <span className="text-[#3bda48]">Verified</span>
                ) : (
                  userData?.isAdminVerified === false && (
                    <span className="text-[#D71212]">Not Verified</span>
                  )
                )}
              </p>

              <form className="d-lg-block d-flex gap-2 justify-between">
                {steps.map((step, index) => {
                  const isCompleted = completedSteps.includes(step);
                  const isSelected = selectedStep === step;
                  return (
                    <div
                      key={index}
                      className="md:flex text-center items-center gap-1 mb-3"
                    >
                      <div
                        className={`w-[24px] check_btn rounded-full h-[24px] border-1 flex items-center justify-center ${
                          isCompleted ? "bg-green-500" : "border-gray-500"
                        } ${isSelected ? "border-green-500" : ""}`}
                        style={{ borderColor: isSelected ? "green" : "" }}
                      >
                        {isCompleted && (
                          <FaCheck className="text-white text-sm" />
                        )}
                      </div>
                      <span className="md:text-[16px] text-[14px]">{step}</span>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
          <div className="col-lg-9">
            {selectedStep === "Identification Details" && (
              <IdentificationDetails
                userData={userData}
                handleNext={handleNext}
              />
            )}
            {selectedStep === "Upload Documents" && (
              <UploadDocuments userData={userData} handleNext={handleNext} />
            )}
            {selectedStep === "Withdrawal Option" && (
              <WithdrawalOption userData={userData} handleNext={handleNext} />
            )}
            {selectedStep === "Review" && <Review />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountVerify;
