import React from "react";
import Index from "../Components/sign-in/Index";

const SignIn = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default SignIn;
