import React from "react";

function DefaultPage() {
  return (
    <div className="bg-black w-[100vw] h-[100vh] flex items-center justify-center">
      <div className="text-white text-4xl animate-fadeInOut">
        Coming Soon....
      </div>
    </div>
  );
}

export default DefaultPage;
