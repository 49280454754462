import React, { useEffect } from 'react';
import Header from './headers/header';
import Footer from './footers/footer';

export default function Wrapper({ children }) {
    return (
        <>
        <Header />
            {children}
            
            {/* <Footer /> */}
            {/* <ToastContainer /> */}
        </>
    )
}
